import React from "react";

const Loading = () => {
  return (
    <div className="font-Comfortaa w-full">
      <section className="mt-10">
        <div className="container min-h-fit mx-auto flex items-center relative shadow-xl">
          <div className="absolute h-full w-full flex items-center justify-center">
            <div className="flex items-center">
              <span className="text-2xl tracking-wider mr-4 dark:text-parewacreamcanprimary-200">
                Loading
              </span>
              <svg
                className="animate-spin h-8 w-8 text-parewacreamcanprimary-200 dark:text-parewamatrixsecondary-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loading;
