import Swal from "sweetalert2";
import { hitApi } from "../components/services/hitApi.js";

/**
 * Generates an Instagram story from a news article using the backend API
 *
 * @param {Object} news - The news article object
 * @param {string} parewaLogo - URL to Parewa logo
 * @returns {Promise} - Promise that resolves when story is generated
 */
export const generateInstagramStory = async (news, parewaLogo) => {
  // Show loading message
  Swal.fire({
    title: "Creating story image...",
    text: "Please wait while we generate your Instagram story",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });

  try {
    // Generate random theme index
    const themeIndex = Math.floor(Math.random() * 8);

    // Call the backend API to generate the Instagram story image
    const response = await hitApi({
      method: "POST",
      url: `/news/${news._id}/instagram-story`,
      data: {
        parewaLogo,
        theme: themeIndex,
      },
      responseType: "blob", // Important: we need the raw binary data
    });

    // Convert blob to data URL
    const blob = response.data;
    const dataUrl = await blobToDataURL(blob);

    // Handle the generated image based on device
    handleGeneratedImage(dataUrl);

    // Close loading dialog
    Swal.close();

    return dataUrl;
  } catch (error) {
    console.error("Error generating Instagram story:", error);
    Swal.fire({
      title: "Error",
      text: "There was a problem creating your Instagram story. Please try again.",
      icon: "error",
      confirmButtonText: "OK",
    });
    throw error;
  }
};

/**
 * Convert Blob to Data URL
 *
 * @param {Blob} blob - Image blob
 * @returns {Promise<string>} - Data URL
 */
const blobToDataURL = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

/**
 * Handles the generated Instagram story image based on device type
 *
 * @param {string} dataUrl - The generated image data URL
 */
const handleGeneratedImage = (dataUrl) => {
  // Check if user is on mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    // For mobile devices, offer direct share options
    Swal.fire({
      title: "Share to Instagram",
      html: `
        <div class="flex flex-col items-center gap-4">
          <img src="${dataUrl}" class="w-full max-w-xs rounded-lg shadow-lg mb-4" alt="Instagram Story Preview">
          <p class="text-sm text-center mb-4">Image created! Choose how you want to share it:</p>
          
          <div class="grid grid-cols-2 gap-3 w-full">
            <button id="open-instagram" class="flex flex-col items-center justify-center p-3 bg-gradient-to-br from-purple-500 via-pink-500 to-orange-500 text-white rounded-lg hover:opacity-90 transition-opacity">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line></svg>
              <span class="text-xs mt-1">Open Instagram</span>
            </button>
            
            <button id="download-image" class="flex flex-col items-center justify-center p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
              <span class="text-xs mt-1">Download Image</span>
            </button>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      didOpen: () => {
        // Open Instagram
        document
          .getElementById("open-instagram")
          .addEventListener("click", () => {
            // Try to use the Mobile Share API if available
            if (navigator.share) {
              // Create a blob from the data URL
              fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                  const file = new File([blob], "parewa-news-story.png", {
                    type: "image/png",
                  });
                  navigator
                    .share({
                      files: [file],
                      title: "Parewa News Story",
                      text: "Check out this news from Parewa!",
                    })
                    .catch((err) => {
                      console.warn("Share API failed:", err);
                      // If share API fails, fallback to opening Instagram
                      setTimeout(() => {
                        window.location.href = "instagram://camera";
                        // Fallback if app doesn't open
                        setTimeout(() => {
                          window.open("https://www.instagram.com/", "_blank");
                        }, 1000);
                      }, 500);
                    });
                })
                .catch((err) => {
                  console.warn("Error creating file from blob:", err);
                  // Fallback to direct Instagram open
                  setTimeout(() => {
                    window.location.href = "instagram://camera";
                    // Fallback if app doesn't open
                    setTimeout(() => {
                      window.open("https://www.instagram.com/", "_blank");
                    }, 1000);
                  }, 500);
                });
            } else {
              // If Share API not available, fallback to opening Instagram
              // First, save the image
              const downloadLink = document.createElement("a");
              downloadLink.href = dataUrl;
              downloadLink.download = "parewa-news-story.png";
              downloadLink.click();

              setTimeout(() => {
                window.location.href = "instagram://camera";
                // Fallback if app doesn't open
                setTimeout(() => {
                  window.open("https://www.instagram.com/", "_blank");
                }, 1000);
              }, 500);
            }

            Swal.fire({
              title: "Opening Instagram",
              text: "We've saved the image to your device. Now open your Instagram app, tap the + button, select the image from your gallery, and share it to your story!",
              icon: "success",
              confirmButtonText: "Got it",
            });
          });

        // Download image
        document
          .getElementById("download-image")
          .addEventListener("click", () => {
            const downloadLink = document.createElement("a");
            downloadLink.href = dataUrl;
            downloadLink.download = "parewa-news-story.png";
            downloadLink.click();

            Swal.fire({
              title: "Image Downloaded",
              text: "The story image has been saved to your device.",
              icon: "success",
              confirmButtonText: "OK",
            });
          });
      },
    });
  } else {
    // For desktop - download and show instructions
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUrl;
    downloadLink.download = "parewa-news-story.png";
    downloadLink.click();

    // Show success message with preview
    Swal.fire({
      title: "Instagram Story Created",
      html: `
        <div class="flex flex-col items-center">
          <img src="${dataUrl}" class="w-full max-w-xs rounded-lg shadow-lg mb-4" alt="Instagram Story Preview">
          <p class="text-sm">Your Instagram story image has been downloaded. Share it using the Instagram app!</p>
        </div>
      `,
      icon: "success",
      confirmButtonText: "Got it",
    });
  }
};

/**
 * Shows Instagram sharing instructions with option to create a story
 *
 * @param {Object} news - The news article object
 * @param {string} parewaLogo - URL to Parewa logo
 */
export const showInstagramInstructions = (news, parewaLogo) => {
  // Check if user is on mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if (isMobile) {
    // For mobile users, go directly to story generation
    generateInstagramStory(news, parewaLogo);
  } else {
    // For desktop users, show instructions first
    Swal.fire({
      title: "Share to Instagram",
      html: `
        <div class="flex flex-col gap-4 my-6 text-left">
          <p class="mb-4">Create an Instagram-ready story image of this news article:</p>
          
          <div class="flex flex-col gap-2">
            <button id="generate-instagram-story" class="w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white py-3 px-4 rounded-lg font-medium hover:from-purple-600 hover:to-pink-600 transition-all flex items-center justify-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line></svg>
              Create Instagram Story Image
            </button>
          </div>
          
          <div class="mt-4 bg-gray-100 dark:bg-zinc-700 p-4 rounded-lg">
            <h3 class="font-medium mb-2">How to share to Instagram:</h3>
            <ol class="list-decimal list-inside space-y-2 text-sm">
              <li>Click the button above to download the Instagram story image</li>
              <li>Open Instagram app on your phone</li>
              <li>Tap the "+" icon to add a new story</li>
              <li>Select the downloaded image from your gallery</li>
              <li>Add any additional stickers or text you want</li>
              <li>Share to your story!</li>
            </ol>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        container: "instagram-dialog-container",
        popup: "instagram-dialog-popup",
      },
      didOpen: () => {
        document
          .getElementById("generate-instagram-story")
          .addEventListener("click", () =>
            generateInstagramStory(news, parewaLogo)
          );
      },
    });
  }
};
