import React from "react";

const TermsPage = () => {
  return (
    <div>
      <article className="markdown mx-auto w-full max-w-4xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert dark:text-white relative z-10">
        <header className="mb-4 lg:mb-6 not-format">
          <h1 className="mb-4 text-3xl font-extrabold leading-tight text-zinc-900 lg:mb-6 lg:text-4xl dark:text-white">
            Terms and Conditions
          </h1>
          <p className="text-base text-zinc-500 dark:text-zinc-400">
            Effective Date: 9th October 2024
          </p>
        </header>

        <p className="font-sans font-normal dark:text-white">
          Welcome to Parewa! By using our website (
          <a href="https://www.parewa.news">www.parewa.news</a>) and services,
          you agree to comply with and be bound by the following terms and
          conditions. Please read them carefully before using our services. If
          you do not agree with any part of these terms, you should not use the
          website.
        </p>

        <section>
          <h2 className="text-xl font-bold mt-6 mb-3">
            1. Acceptance of Terms
          </h2>
          <p className="mb-4 font-sans font-normal">
            By accessing and using Parewa, you accept and agree to these terms,
            as well as our Privacy Policy. These terms apply to all visitors,
            users, and others who access or use the website.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">2. Use of Content</h2>
          <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
            <li>
              Parewa aggregates news articles and links from various sources,
              which are the property of the respective news portals and
              websites.
            </li>
            <li>
              Users may not copy, reproduce, distribute, or modify any content
              without prior written permission from the respective owners.
            </li>
            <li>
              You may link to the content on Parewa, provided you give
              appropriate credit and do not alter the content or suggest any
              association with Parewa without consent.
            </li>
          </ul>

          <h2 className="text-xl font-bold mt-6 mb-3">
            3. Intellectual Property
          </h2>
          <p className="mb-4 font-sans font-normal">
            All trademarks, logos, and content displayed on Parewa are the
            intellectual property of Parewa Inc. or third-party licensors.
            Unauthorized use of any trademarks or content without prior written
            permission is prohibited.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">4. User Conduct</h2>
          <span className="font-sans font-normal mb-1">
            Users agree not to:
          </span>
          <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
            <li>
              Engage in any activity that disrupts or interferes with the proper
              functioning of the website.
            </li>
            <li>
              Use the website to distribute spam, viruses, or any other harmful
              or unauthorized material.
            </li>
            <li>
              Engage in illegal activities or use the website for fraudulent
              purposes.
            </li>
          </ul>

          <h2 className="text-xl font-bold mt-6 mb-3">5. External Links</h2>
          <p className="mb-4 font-sans font-normal">
            Parewa may contain links to third-party websites not owned or
            controlled by Parewa Inc. We have no control over, and assume no
            responsibility for, the content, privacy policies, or practices of
            any third-party websites. By using Parewa, you acknowledge and agree
            that Parewa Inc. is not responsible for any damages or losses caused
            by your use of such third-party websites.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">6. Disclaimers</h2>
          <ul className="list-disc list-inside space-y-2 mb-4 font-sans font-normal">
            <li>
              The content on Parewa is provided for general informational
              purposes only and is subject to change. We do not guarantee the
              accuracy, completeness, or reliability of the content.
            </li>
            <li>
              Parewa is not liable for any damages resulting from the use or
              inability to use the website, or for any reliance on information
              provided through the website.
            </li>
          </ul>

          <h2 className="text-xl font-bold mt-6 mb-3">
            7. Limitation of Liability
          </h2>
          <p className="mb-4 font-sans font-normal">
            In no event shall Parewa Inc., its directors, employees, or partners
            be liable for any indirect, incidental, or consequential damages
            arising out of your use of or inability to use the website or its
            content.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">8. Modifications</h2>
          <p className="mb-4 font-sans font-normal">
            We reserve the right to modify or replace these terms at any time.
            Any changes will be posted on this page, and it is your
            responsibility to review these terms regularly. Continued use of
            Parewa after any changes signifies your acceptance of the new terms.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">9. Governing Law</h2>
          <p className="mb-4 font-sans font-normal">
            These terms are governed by and construed in accordance with the
            laws of Nepal. Any disputes arising out of or relating to these
            terms will be subject to the exclusive jurisdiction of the courts in
            Kathmandu, Nepal.
          </p>

          <h2 className="text-xl font-bold mt-6 mb-3">
            10. Contact Information
          </h2>
          <p className="mb-4 font-sans font-normal">
            If you have any questions about these terms, please contact us at{" "}
            <a href="mailto:info@parewainc.com">info@parewainc.com</a>.
          </p>
        </section>
      </article>
    </div>
  );
};

export default TermsPage;
