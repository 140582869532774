import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetUserByIdQuery } from "../components/services/api/usersService.js";
import { fetchUserDetails } from "../slice/authSlice.js";
import useToken from "./useToken.js";

const useUser = () => {
  const token = useToken();
  const { id: paramId } = useParams();
  const dispatch = useDispatch();
  const userFromStore = useSelector((state) => state.auth.user);
  const [user, setUser] = useState(userFromStore);
  const [loading, setLoading] = useState(true);

  const userId = paramId || userFromStore?._id;

  const { refetch, data } = useGetUserByIdQuery(
    { id: userId, token },
    { skip: !userId || !token }
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!userId || !token) return;

      try {
        // Fetch user details
        if (!userFromStore) {
          dispatch(fetchUserDetails({ token, userId }));
        }

        // Refetch user data if necessary
        refetch();

        // Set user data from API response
        if (data) {
          const { user, data: additionalData } = data;
          setUser({
            ...user,
            savedNewsCount: additionalData.savedNewsCount,
            totalLikes: additionalData.totalLikes,
            totalDislikes: additionalData.totalDislikes,
            likedNews: additionalData.likedNews,
            dislikedNews: additionalData.dislikedNews,
            savedNews: additionalData.savedNews,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, refetch, data, userFromStore, dispatch]);

  return { user, loading };
};

export default useUser;
