import React, { useState } from "react";
import { hitApi } from "../services/hitApi";
import { useNavigate } from "react-router-dom";

const VerifyOtp = ({ email }) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await hitApi.post("/users/verify-otp", { email, otp });
      const { token } = response.data;
      localStorage.setItem("token", token); // Store the token in local storage
      alert("OTP verified successfully");
      navigate("/reset-password-form"); // Redirect to reset-password-form
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Error verifying OTP");
    }
  };

  return (
    <form className="w-full mt-8 p-2">
      <div className="relative flex items-center">
        <span className="absolute">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mx-3 text-zinc-300 dark:text-zinc-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
        </span>

        <input
          type="text"
          className="block w-full py-3 text-zinc-700 bg-white border rounded-lg px-11 dark:bg-zinc-600 dark:text-zinc-300 dark:border-zinc-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        ></input>
      </div>
      <button
        onClick={handleSubmit}
        className="mt-4 px-6 py-3 text-sm font-bold tracking-tight text-white hover:text-black capitalize transition-colors duration-500 transform bg-zinc-900 rounded-lg w-full hover:bg-zinc-500 focus:outline-none focus:ring focus:ring-zinc-300 focus:ring-opacity-50"
      >
        Verify OTP
      </button>
    </form>
  );
};

export default VerifyOtp;
