import React from "react";
import { useNavigate } from "react-router-dom";

const EmailVerified = () => {
  const navigate = useNavigate();

  const handleContinueToLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex items-center justify-center h-screen">
      <div>
        <div className="flex flex-col items-center gap-10">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="text-green-600 w-40 h-40"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h1 className="text-2xl text-center font-semibold font-Comfortaa">
            Your email has been{" "}
            <span className="text-parewacreamcanprimary-300">
              Verified Successfully
            </span>
            .<br />
            Thank you!
          </h1>

          <button
            onClick={handleContinueToLogin}
            className="inline-flex items-center px-4 py-4 mx-auto text-white hover:text-parewacreamcanprimary-200 bg-zinc-600 border border-zinc-900 rounded-full hover:bg-zinc-900 focus:outline-none focus:ring"
          >
            Continue to Login
            <svg
              className="w-4 h-4 ml-2"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
