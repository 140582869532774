export const ToTitleCase = (str) => {
  if (!str) {
    return "";
  }

  return str
    .replace(/_/g, " ") // replace underscores with spaces
    .toLowerCase() // convert to lowercase
    .split(" ") // split into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
    .join(" "); // join the words back into a single string
};
