import React, { useEffect } from "react";
import { useGetNewsByCategoryQuery } from "../../../services/api/categoryServices";
import { Carousel } from "antd";
import { Link } from "react-router-dom";
import sourceImages from "../../../../utils/sourceImages";
import LoadingNew from "../../../LoadingSkeleton";
import { GiTeamUpgrade } from "react-icons/gi";
import { BsDot } from "react-icons/bs";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;
const stockImageBreakingNews = `${bucketUrl}/backend/assets/stockImages/breaking_news/first_try.svg`;

const HeroSectionV2 = ({ toggleSourceName }) => {
  const { data, isLoading, error, refetch, startedTimeStamp } =
    useGetNewsByCategoryQuery(
      {
        category: decodeURIComponent("Breaking News"),
        page: 1,
        itemsPerPage: 20,
      },
      {
        // Enable pollingInterval defined in the API
        pollingInterval: 120000,
        // Refetch on window focus
        refetchOnFocus: true,
        // Refetch on reconnection
        refetchOnReconnect: true,
      }
    );

  const newsAccordingToCategory = data?.data?.news;

  // Manual refresh on component mount and cleanup
  useEffect(() => {
    // Optional: Force an initial fetch when component mounts
    refetch();

    // Optional: Log when data was last fetched
    // if (startedTimeStamp) {
    //   console.log("Data last fetched at:", new Date(startedTimeStamp));
    // }

    // Setup an interval for manual refetch (as a backup)
    const intervalId = setInterval(() => {
      refetch();
    }, 120000); // 2 minutes

    // Clean up interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [refetch, startedTimeStamp]);

  if (isLoading) return <LoadingNew />;
  if (error) return <p>Error: {error.message || "Failed to fetch news"}</p>;
  if (!newsAccordingToCategory || newsAccordingToCategory.length === 0) {
    return <p>No news articles available</p>;
  }

  return (
    <div className="grid grid-rows-1 w-full h-fit">
      {/* Header */}
      <div className="grid grid-cols-2 xxs:grid-cols-1 md:grid-cols-2 h-fit">
        <div className="xxs:px-0 sm:px-10 xxs:pt-0 sm:pt-2">
          <Carousel autoplay adaptiveHeight={true} effect="fade">
            {newsAccordingToCategory.slice(0, 4).map((news) => (
              <div key={news._id} className="relative">
                <div className="relative h-fit overflow-hidden">
                  <img
                    src={news.image_source || ""}
                    alt={news.title || "Breaking News"}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite error loops
                      e.target.src = stockImageBreakingNews;
                    }}
                  />
                  <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent text-left">
                    <h3 className="news-card-category-body-hero-v2">
                      {news.category[0]}
                    </h3>
                    <Link to={`/news/${news._id}`}>
                      <h3 className="text-white text-2xl font-bold mb-2">
                        {news.title
                          ? new DOMParser()
                              .parseFromString(news.title, "text/html")
                              .body.textContent.split(" ")
                              .slice(0, 10)
                              .join(" ")
                          : ""}
                      </h3>
                      <div className="text-sm text-zinc-400 line-clamp-1 font-Comfortaa my-2">
                        <p className="xxs:flex justify-start text-[12px] sm:text-zinc-900 items-center">
                          <img
                            src={sourceImages[news.source_name] || parewaImg}
                            width={25}
                            height={25}
                            className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                            alt="source_img"
                          ></img>

                          <span className="news-card-source-hero-v2 ml-2 text-white">
                            {news.source_name}
                          </span>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>

          <div className="h-fit w-full p-6 dark:text-white flex justify-between bg-zinc-900 dark:bg-zinc-800 mt-4 xxs:rounded-none md:rounded-lg">
            <div className="flex items-center text-white gap-3 w-2/3">
              <GiTeamUpgrade size={25} />
              <h2 className="text-xs sm:text-base font-bold font-Comfortaa">
                Work with our{" "}
                <span className="text-parewacreamcanprimary-200">
                  professional team
                </span>
              </h2>
            </div>

            <button
              onClick={() => {
                console.log("Join Our Team");
              }}
              className="flex w-1/3 items-center gap-2 bg-parewacreamcanprimary-200 hover:bg-parewacreamcanprimary-300 text-white px-4 py-2 rounded-sm font-Comfortaa cursor-pointer"
            >
              <span className="text-xs w-full">Join Our Team</span>
              <svg
                className="w-6 h-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </div>
        </div>

        {/* Latest Breaking News */}
        <div className="xxs:p-4 md:p-0">
          <h2 className="font-bold font-Comfortaa xxs:text-sm sm:text-lg mb-4 dark:text-parewacreamcanprimary-200">
            Latest Breaking News
          </h2>
          <div className="space-y-4 h-fit">
            {newsAccordingToCategory.slice(4, 8).map((news) => (
              <Link
                key={news._id}
                to={`/news/${news._id}`}
                className="block dark:hover:shadow-lg dark:hover:bg-zinc-800 dark:hover:bg-opacity-25 hover:bg-zinc-100 p-2 rounded transition-all"
              >
                <div className="flex gap-3">
                  <img
                    src={news.image_source || ""}
                    alt={news.title || "Breaking News"}
                    className="w-[120px] h-[80px] object-cover rounded"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite error loops
                      e.target.src = stockImageBreakingNews;
                    }}
                  />
                  <div>
                    <h3 className="font-semibold dark:text-white">
                      {news.title
                        ? new DOMParser().parseFromString(
                            news.title,
                            "text/html"
                          ).body.textContent
                        : ""}
                    </h3>
                    <div className="text-sm text-zinc-400 line-clamp-1 font-Comfortaa my-2">
                      <p className="xxs:flex justify-start text-[12px] sm:text-zinc-900 items-center">
                        <img
                          src={sourceImages[news.source_name] || parewaImg}
                          width={25}
                          height={25}
                          className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                          alt="source_img"
                        ></img>

                        <span className="news-card-source-hero-v2 ml-2">
                          {news.source_name}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* Latest Breaking News */}
      </div>
      {/* Header */}

      {/* Footer */}
      <div className="grid grid-cols-3 xxs:grid-cols-1 md:grid-cols-3 row-span-1">
        <div className="xxs:col-span-1 md:col-span-2">
          <div className="grid grid-cols-2 xxs:gap-0 md:gap-4">
            {newsAccordingToCategory.slice(8, 12).map((news) => (
              <Link
                key={news._id}
                to={`/news/${news._id}`}
                className="block dark:hover:shadow-lg dark:hover:bg-zinc-800 dark:hover:bg-opacity-25 hover:bg-zinc-100 p-2 rounded transition-all"
              >
                <img
                  src={news.image_source || ""}
                  alt={news.title || "Breaking News"}
                  className="w-full h-[250px] object-cover object-top rounded mb-2"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = stockImageBreakingNews;
                  }}
                />
                <h3 className="font-semibold dark:text-white my-2">
                  {news.title
                    ? new DOMParser().parseFromString(news.title, "text/html")
                        .body.textContent
                    : ""}
                </h3>
                <div className="text-sm text-zinc-400 line-clamp-2 my-2 font-Comfortaa">
                  <p className="xxs:flex justify-start text-[12px] sm:text-zinc-900 items-center">
                    <img
                      src={sourceImages[news.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>

                    <span className="news-card-source-hero-v2 ml-2">
                      {news.source_name}
                    </span>
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="col-span-1 p-2 xxs:mt-4">
          <h2 className="font-bold text-xl mb-4 dark:text-parewacreamcanprimary-200">
            Trending
          </h2>
          <div className="space-y-3">
            {newsAccordingToCategory.slice(12, 20).map((news, index) => (
              <Link
                key={news._id}
                to={`/news/${news._id}`}
                className="flex items-start gap-2 dark:hover:shadow-lg dark:hover:bg-zinc-800 dark:hover:bg-opacity-25 hover:bg-zinc-100 p-2 rounded transition-all"
              >
                <span className="font-bold text-xl text-parewamatrixprimary-200 mr-1">
                  <BsDot />
                </span>
                <p className="font-medium line-clamp-2 dark:text-white">
                  {news.title
                    ? new DOMParser().parseFromString(news.title, "text/html")
                        .body.textContent
                    : ""}
                </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* Footer */}
    </div>
  );
};

export default HeroSectionV2;
