import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { motion } from "framer-motion";
import { ArrowUpRightFromCircle, BadgeHelp, MoreVertical } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import sourceImages from "../../../utils/sourceImages.js";
import CardButtons from "../CardButtons/CardButtons.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsWithoutImage = ({
  news,
  setShowLoginAlert,
  setButtonName,
  buttonName,
  toggleSourceName,
}) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [showLikePopup, setShowLikePopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToNewsSource = () => {
    const a = document.createElement("a");
    a.href = news.source_url;
    const baseUrl = `${a.protocol}//${a.hostname}`;
    window.open(baseUrl, "_blank");
  };

  const verifyPage = () => {
    navigate("/verify-page");
  };

  const handlePopUpLike = (action) => {
    setShowLikePopup(true);
    setTimeout(() => {
      setShowLikePopup(false);
    }, 1000);
  };

  const handleCardClick = (e) => {
    // Navigate to news detail page
    navigate(`/news/${news?._id}`);
  };

  const handleSpecificClick = (e) => {
    // Prevent clicks within these elements from triggering the card navigation
    e.stopPropagation();
  };

  return (
    <div className="relative h-full">
      {/* Card container with rounded corners but no overflow restriction */}
      <div
        key={news._id}
        className="flex w-full h-full rounded-lg dark:bg-zinc-800 bg-zinc-100 relative cursor-pointer"
        onClick={handleCardClick}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 rounded-lg bg-gradient-to-br from-transparent via-transparent to-zinc-300 dark:to-zinc-900 pointer-events-none"></div>

        {showLikePopup && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25 z-10 rounded-lg">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 60,
                damping: 10,
                duration: 0.8,
                ease: "easeInOut",
              }}
              className="p-4 rounded-lg text-white"
            >
              <HeartFill size={36} />
            </motion.div>
          </div>
        )}
        <div
          id={`news-${news.source_name}`}
          className="flex-col p-2 flex font-sans justify-between w-full relative z-2"
        >
          {/* TITLE AND DESCRIPTION */}
          <div className="flex flex-col h-full w-full">
            <div className="flex items-center justify-between">
              <span className="m-2 w-fit news-card-category-body text-white">
                {ToTitleCase(news.type || news.category[0] || "Thinking")}
              </span>
              <div className="mr-4" onClick={handleSpecificClick}>
                <div className="relative flex" ref={dropdownRef}>
                  <button
                    className="hover:text-zinc-800 dark:hover:text-parewacreamcanprimary-100 cursor-pointer text-zinc-600 dark:text-zinc-300"
                    title="More Options"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <MoreVertical size={24} strokeWidth={2} />
                  </button>

                  {showDropdown && (
                    <div className="origin-top-right z-50 absolute top-2 right-0 mt-2 w-56 rounded-md shadow-xl bg-zinc-100 dark:bg-zinc-700 rounded-lg">
                      <div
                        className="p-2"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        <button
                          className="px-4 py-2 text-sm flex gap-2 items-center text-zinc-700 w-full dark:text-zinc-300 dark:hover:bg-zinc-600 dark:hover:bg-opacity-50 hover:bg-zinc-200 rounded-lg"
                          role="menuitem"
                          onClick={goToNewsSource}
                        >
                          <ArrowUpRightFromCircle size={15} />
                          <span className="text-[11px] tracking-wider font-semibold p-1">
                            {`Go to ${news.source_name}`}
                          </span>
                        </button>

                        <button
                          className="flex gap-2 px-4 py-2 text-sm items-center dark:hover:text-zinc-900 text-zinc-700 w-full dark:text-zinc-300 dark:hover:bg-green-200 dark:hover:bg-opacity-60 hover:bg-green-200 rounded-lg"
                          role="menuitem"
                          onClick={verifyPage}
                        >
                          <BadgeHelp size={15} />{" "}
                          <span className="text-[11px] tracking-wider font-semibold p-1">
                            Verify Page
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full p-2">
              <h3 className="flex news-card-title">
                {news.title
                  ? new DOMParser()
                      .parseFromString(news.title, "text/html")
                      .body.textContent.split(" ")
                      .slice(0, 10)
                      .join(" ")
                  : ""}
              </h3>

              <p className="mt-4 tracking-wider news-card-description 2xl:h-40 xxs:h-20">
                {news.description
                  ? new DOMParser()
                      .parseFromString(news.description, "text/html")
                      .body.textContent.slice(0, 70) + "..."
                  : ""}
              </p>
            </div>
          </div>
          {/* TITLE AND DESCRIPTION */}

          <div className="flex flex-col md:flex-col w-full justify-between font-Comfortaa">
            {/* Published Time and News Source */}
            <div className="items-center w-full px-5 mx-auto py-2">
              <div className="flex justify-between">
                <div className="flex cursor-vertical-text p-1 items-center gap-2">
                  {news.source_name !== "Annapurna Post" &&
                    news.source_name !== "Kathmandu Post" &&
                    news.source_name !== "Ajako Artha" && (
                      <div className="flex-col">
                        <p className="text-xs dark:text-gray-300 text-black">
                          {new Date(news.pub_date).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                        <p className="xxs:text-[9px] lg:text-[10px] font-light dark:text-gray-300 text-black">
                          Time
                        </p>
                      </div>
                    )}

                  <div className="flex-col">
                    <p className="text-xs dark:text-gray-300 text-black">
                      {new Date(news.pub_date).toLocaleDateString()}
                    </p>
                    <p className="xxs:text-[9px] lg:text-[10px] font-light dark:text-gray-300 text-black">
                      Date
                    </p>
                  </div>
                </div>

                <div
                  className="flex p-1 overflow-hidden"
                  onClick={handleSpecificClick}
                >
                  <p className="xxs:flex justify-end text-[12px] text-black items-center">
                    <img
                      src={sourceImages[news.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>

                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSourceName(news.source_name);
                      }}
                      className="news-card-source ml-2 cursor-pointer"
                    >
                      {news.source_name}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* Published Time and News Source */}

            <div onClick={handleSpecificClick}>
              <CardButtons
                handlePopUpLike={handlePopUpLike}
                news={news}
                setShowLoginAlert={setShowLoginAlert}
                setButtonName={setButtonName}
                buttonName={buttonName}
                parentComponent="NewsWithoutImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsWithoutImage;
