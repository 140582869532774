import { Trophy } from "@styled-icons/bootstrap/Trophy";
import { Music } from "@styled-icons/boxicons-regular/Music";
import { LearningApp } from "@styled-icons/fluentui-system-regular/LearningApp";
import { News } from "@styled-icons/fluentui-system-regular/News";
import { NotebookSubsection } from "@styled-icons/fluentui-system-regular/NotebookSubsection";
import { StyleGuide } from "@styled-icons/fluentui-system-regular/StyleGuide";
import { Video } from "@styled-icons/fluentui-system-regular/Video";
import { Fitness } from "@styled-icons/ionicons-outline/Fitness";
import { Business } from "@styled-icons/ionicons-sharp/Business";
import { GameController } from "@styled-icons/ionicons-solid/GameController";
import { Dining } from "@styled-icons/material-outlined/Dining";
import { FamilyRestroom } from "@styled-icons/material-outlined/FamilyRestroom";
import { DevicesOther } from "@styled-icons/material-rounded/DevicesOther";
import { HomeHeart } from "@styled-icons/remix-line/HomeHeart";
import { TravelCase } from "@styled-icons/zondicons/TravelCase";
import { Code2, Compass, Map, Popcorn, UsersRound } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTypes } from "../../../slice/typesSlice";
import { useGetDistinctCategoriesQuery } from "../../services/api/categoryServices";
import { useNavigate } from "react-router-dom";

const typeIcons = {
  "arts_&_culture": <UsersRound size={25} />,
  "business_&_entrepreneurs": <Business size={25} />,
  "celebrity_&_pop_culture": <Popcorn size={25} />,
  "diaries_&_daily_life": <NotebookSubsection size={25} />,
  family: <FamilyRestroom size={25} />,
  "fashion_&_style": <StyleGuide size={25} />,
  "film_tv_&_video": <Video size={25} />,
  "fitness_&_health": <Fitness size={25} />,
  "food_&_dining": <Dining size={25} />,
  gaming: <GameController size={25} />,
  "learning_&_educational": <LearningApp size={25} />,
  music: <Music size={25} />,
  "news_&_social_concern": <News size={25} />,
  other_hobbies: <DevicesOther size={25} />,
  relationships: <HomeHeart size={25} />,
  "science_&_technology": <Code2 size={25} />,
  sports: <Trophy size={25} />,
  "travel_&_adventure": <TravelCase size={25} />,
  uncertain: <Map size={25} />,
  "youth_&_student_life": <Compass size={25} />,
  // Add more icons as needed
};

const CategorySelection = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("english"); // Default to English
  const navigate = useNavigate();

  // Existing Redux selectors for types (English categories)
  const data = useSelector((state) => state.types.types);
  const status = useSelector((state) => state.types.status);
  const dispatch = useDispatch();

  // Query for Nepali categories
  const { data: nepaliCategories } = useGetDistinctCategoriesQuery(undefined, {
    skip: selectedLanguage === "english",
  });

  useEffect(() => {
    if (status === "idle" && selectedLanguage === "english") {
      dispatch(fetchTypes());
    }
  }, [status, dispatch, selectedLanguage]);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleCategoryClick = (category) => {
    if (selectedLanguage === "nepali") {
      navigate(`/news/category/${encodeURIComponent(category)}`);
    }
    if (selectedLanguage === "english") {
      navigate(`/news?type=${encodeURIComponent(category)}`);
    }
  };

  const displayCategories =
    selectedLanguage === "english" ? data : nepaliCategories?.categories || [];

  return (
    <div className="w-full flex flex-col items-start px-6 py-12">
      <div className="flex items-center gap-4 mb-4">
        <h1 className="font-Comfortaa text-2xl ml-2 dark:text-white">
          Explore via News Categories 📰
        </h1>
        <select
          value={selectedLanguage}
          onChange={handleLanguageChange}
          className="px-4 py-2 rounded-sm border border-zinc-300 dark:border-zinc-700 dark:bg-zinc-800 dark:text-white"
        >
          <option value="english">English</option>
          <option value="nepali">नेपाली</option>
        </select>
      </div>

      <div className="flex flex-wrap">
        {displayCategories.map((item, index) => (
          <div
            className="w-fit flex flex-wrap items-center"
            key={index}
            onClick={() => handleCategoryClick(item)}
            role="button"
            tabIndex={0}
          >
            <div className="w-fit flex items-center bg-parewacreamcanprimary-100 rounded-lg px-4 py-2 m-2 gap-1 cursor-pointer hover:bg-parewacreamcanprimary-200 transition-colors">
              {selectedLanguage === "english" && typeIcons[item]}
              <p className="text-xs capitalize font-Comfortaa">
                {selectedLanguage === "english"
                  ? item.replace(/_/g, " ")
                  : item}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategorySelection;
