import { Moon, Sun } from "lucide-react";
import NepaliDate from "nepali-date";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMode } from "../slice/authSlice.js";
import { htmlDateFormat } from "../utils/htmlDateFormat.js";
import SearchBar from "./SearchBar/SearchBar.js";
import { DividerTall } from "@styled-icons/fluentui-system-filled/DividerTall";
import { motion } from "framer-motion";
import Sidebar from "./SideBar/SideBarMain.jsx";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaLogoLight = `${bucketUrl}/backend/assets/e-parewa_dark.png`;
const parewaLogoDark = `${bucketUrl}/backend/assets/e-parewa_light.png`;

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [temperature, setTemperature] = useState(null);
  const theme = useSelector((state) => state.auth.mode); // get theme from Redux store
  const dispatch = useDispatch();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const reduxUser = useSelector((state) => state.auth.user);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Get the current UTC date/time
  const now = new Date();

  // Calculate Nepal Standard Time (NST) offset in milliseconds
  // Nepal is +5:45 from UTC, so (5*60 + 45) minutes * 60 seconds * 1000 milliseconds
  const nstOffset = (5 * 60 + 45) * 60 * 1000;

  // Get the UTC time in milliseconds and add the NST offset
  const nstTime = now.getTime() + nstOffset;

  // Adjust the date to NST
  const adjustedDateForNST = new Date(nstTime);

  // Subtract one day from the adjusted date
  // adjustedDateForNST.setDate(adjustedDateForNST.getDate());

  // Now create the NepaliDate object with the adjusted date
  const dateInNepali = new NepaliDate(adjustedDateForNST).format(
    "mmmm d, yyyy"
  );

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  useEffect(() => {
    const storedUser =
      reduxUser ||
      JSON.parse(localStorage.getItem("user")) ||
      JSON.parse(sessionStorage.getItem("user"));
    setUser(storedUser);
  }, [reduxUser]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <div
      className={`max-w-screen w-full h-fit mx-auto ${
        theme === "dark"
          ? "bg-zinc-900 text-white border-2 border-zinc-800"
          : "bg-parewacreamcanprimary-100 shadow-lg"
      } flex flex-col justify-between items-center antialiased fixed z-20`}
    >
      {/* Feedback FORM */}
      <motion.div
        className="h-fit w-full flex justify-center items-center text-xs gap-1 bg-white py-1 dark:bg-parewacreamcanprimary-200 dark:text-black xxs:flex-col sm:flex-row"
        initial={{ y: -250 }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.2,
          type: "spring",
          stiffness: 120,
          duration: 1.5,
        }}
      >
        We value your input! Please take a moment to share your thoughts in our
        survey -
        <a
          href="https://forms.gle/w9sm1C2WLyzuLkBE8"
          target="_blank"
          rel="noreferrer"
          className="text-xs text-blue-400 dark:text-blue-600 hover:underline ml-1"
        >
          Click here to begin
        </a>
      </motion.div>
      {/* Feedback FORM */}
      <nav className="flex items-center justify-between lg:gap-2 w-full xxs:px-0 xl:px-3">
        {/* LOGO Section */}
        <div className="flex w-fit items-center h-fit">
          {/* <img
            onClick={() => {
              navigate("/home");
            }}
            src={parewaImg}
            className="flex-initial p-1 xxs:w-14 xxs:h-10 sm:w-24 sm:h-20 cursor-pointer"
            alt="logo"
          /> */}

          <div className="flex p-2 items-center">
            <button type="primary" onClick={showDrawer}>
              <div className="xxs:space-y-[3px] sm:space-y-1 xxs:p-0 sm:p-1 xxs:mr-1 sm:mr-0">
                <span className="block h-0.5 w-[11px] animate-pulse bg-zinc-900 dark:bg-white"></span>
                <span className="block h-0.5 xxs:w-2 sm:w-[14px] animate-pulse bg-zinc-900 dark:bg-white"></span>
                <span className="block h-0.5 w-[11px] animate-pulse bg-zinc-900 dark:bg-white"></span>
              </div>
            </button>
            <Sidebar
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              user={reduxUser}
            />
          </div>

          <motion.button
            onClick={() => {
              navigate("/home");
            }}
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 120,
              duration: 1.5,
            }}
            className="lg:text-2xl font-Comfortaa lg:font-semibold inline-block capitalize font-medium tracking-wide"
          >
            <img
              src={theme === "dark" ? parewaLogoDark : parewaLogoLight}
              alt="Parewa News Logo"
              className="xxs:h-5 sm:h-8"
            />
          </motion.button>
        </div>
        {/* LOGO Section */}

        {/* Current Weather and DATE and TIME section */}
        <div
          className={`flex flex-col gap-1 items-center w-fit justify-center ${
            theme === "dark" ? "text-white" : "text-black"
          } font-Comfortaa xxs:p-0 sm:p-2`}
        >
          {/* Current Date in ENG and NEP */}
          <div className="flex md:flex-row xxs:mr-2 sm:mr-0 justify-center items-center w-full">
            {/* Get LOCAL DATE in English */}
            <div className="flex flex-col text-right">
              <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm xxs:font-medium lg:font-semibold tracking-wider">
                {htmlDateFormat(new Date(), "ne-NP", {
                  timeZone: "Asia/Kathmandu",
                })}
              </p>
            </div>

            <DividerTall size={30} className="text-parewamatrixprimary-300" />

            {/* GET LOCAL DATE in NEPALI */}
            <div className="flex flex-col text-left items-center">
              <p className="xxs:text-[10px] xs:text-[12px] sm:text-sm font-medium tracking-wider">
                {dateInNepali}
              </p>
            </div>
          </div>

          {/* Current TIME */}
          <div className="w-fit xxs:hidden sm:flex justify-center">
            <p className="xxs:text-xs xxs:font-medium lg:font-semibold tracking-wider">
              {currentTime.toLocaleTimeString("en-US", {
                hour12: true,
                timeZone: "Asia/Kathmandu",
              })}
            </p>
          </div>
        </div>
        {/* Current Weather and DATE section */}

        {/* SEARCH BAR */}
        {!location.pathname.startsWith("/profile") &&
          !location.pathname.startsWith("/terms-and-conditions") &&
          !location.pathname.startsWith("/privacy-policy") &&
          !location.pathname.startsWith("/copyright-intellectual-property") &&
          !location.pathname.startsWith("/search") && (
            <div className="xxs:hidden lg:flex w-1/4">
              <SearchBar setIsNavOpen={setIsNavOpen} isNavOpen={isNavOpen} />
            </div>
          )}
        {/* 
          This condition checks if the current path does not start with "/profile". 
          If it doesn't, the SearchBar is rendered. 
          If it does start with "/profile", the SearchBar is not rendered. 
      */}

        {/* DARK MODE AND LIGHT MODE Switch */}
        <div className="xxs:hidden sm:flex items-center justify-center">
          <button
            className="hover:scale-105 transition-all hover:shadow-xl duration-300 rounded-full"
            onClick={toggleTheme}
          >
            {theme === "light" ? <Moon size={25} /> : <Sun size={25} />}
          </button>
        </div>

        <div className="xxs:hidden sm:flex flex-col items-center justify-center font-Comfortaa p-2 mr-3">
          <button
            id="login_button"
            className="font-medium xxs:text-xs 2xl:text-sm hover:opacity-50 rounded-sm p-2"
            onClick={() => {
              if (user) {
                navigate(`/profile/${user._id}`);
              } else {
                navigate("/login");
              }
            }}
          >
            {user ? "Profile" : "Login"}
          </button>

          {/* The Language part of this section needs to show two buttons: One for English Language: EN and another for Nepali Language: NP */}
          {/* <div className="flex flex-row mt-2 items-center justify-center">
          <button className="text-xs hover:opacity-30">EN</button>
          <GitCommitVertical
            size={20}
            className="text-parewamatrixprimary-300"
          />
          <button className="text-xs hover:opacity-30">NP</button>
        </div> */}
        </div>
      </nav>
    </div>
  );
};

export default Header;
