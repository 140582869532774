import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { Time } from "@styled-icons/ionicons-outline/Time";
import { motion } from "framer-motion";
import { Home, Minus, Tag } from "lucide-react";
import moment from "moment";
import "moment/locale/ne";
import { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sourceImages from "../../utils/sourceImages";
import CardButtons from "../HomePage/CardButtons/CardButtons";
import LazyImage from "../HomePage/LazyImage";
import LoginModal from "../HomePage/Modals/LoginModal";
import SuccessfulAlert from "../HomePage/Modals/SuccessfulAlert.jsx";
import { ToTitleCase } from "../HomePage/NewsCard/utils/ToTitleCase";
import Loading from "../Loading";
import LoadingNew from "../LoadingSkeleton";
import { useGetNewsByTypeQuery } from "../services/api/newsServices";
import FilterButtons from "./FilterButtons/FilterButtons";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsByCategories = ({ type }) => {
  const encodedType = encodeURIComponent(type);
  const itemsPerPage = 16;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSources, setSelectedSources] = useState([]);
  const [sortOption, setSortOption] = useState("By Default");
  const [sourceSelected, setSourceSelected] = useState(false);
  const [sortOptionSelected, setSortOptionSelected] = useState(false);
  const [timeOptionSelected, setTimeOptionSelected] = useState("");
  const [activeSource, setActiveSource] = useState("");
  const [activeSortOption, setActiveSortOption] = useState("");
  const [activeTimeOption, setActiveTimeOption] = useState("");
  //   const state = useSelector((state) => state.auth);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [newsByCategories, setNewsByCategories] = useState([]);
  const [likedArticleId, setLikedArticleId] = useState(null);

  //   let user = state.user;

  const handlePopUpLike = (articleId) => {
    setLikedArticleId(articleId);
    setTimeout(() => {
      setLikedArticleId(null);
    }, 1000);
  };

  const {
    data: dataNewsType,
    isError,
    isSuccess,
    isLoading,
    error,
  } = useGetNewsByTypeQuery({
    type: encodedType,
    source: selectedSources[0] || "All", // Changed to match the toggleSource logic
    sortOption: sortOption,
    timeOption: timeOptionSelected || null,
    page: currentPage,
    itemsPerPage,
  });

  let news = dataNewsType?.newsResults || [];

  useEffect(() => {
    if (isSuccess) {
      setNewsByCategories((prevNews) => {
        if (currentPage === 1) {
          return news;
        }
        const newNews = news.filter(
          (newArticle) =>
            !prevNews.some((prevArticle) => prevArticle._id === newArticle._id)
        );
        return [...prevNews, ...newNews];
      });
    }
    if (isError) {
      console.log("ERROR Occured! :( ", error?.error);
    }
  }, [isSuccess, isError, error, news]);

  useEffect(() => {
    setNewsByCategories([]);
    setCurrentPage(1);
  }, [encodedType, selectedSources, sortOption, timeOptionSelected]);

  if (isLoading) {
    return <LoadingNew />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const totalPages = dataNewsType?.totalPages || 0;

  const handleClick = (e, setActive) => {
    setActive(e.currentTarget.id);
  };

  const handleShowMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  //   const likeNews = async (ACTION, newsId) => {
  //     if (!user) {
  //       // If there's no user in the state, show the login alert and stop the function
  //       setShowLoginAlert(true);
  //       return;
  //     }

  //     const userId = user._id;

  //     hitApi
  //       .patch(`/news/like-activity/${newsId}`, {
  //         user_id: userId,
  //         ACTION: ACTION,
  //       })
  //       .then((res) => {
  //         if (res.data && res.data._id) {
  //           const updatedNews = res.data;
  //           setNewsByCategories((prevNews) =>
  //             prevNews.map((item) =>
  //               item._id === updatedNews._id ? updatedNews : item
  //             )
  //           );
  //         }
  //         setShowModal(true);
  //         setTimeout(() => setShowModal(false), 3000);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error.message);
  //       });
  //   };

  //   const saveNews = (newsId) => {
  //     if (!user) {
  //       setShowLoginAlert(true);
  //       return;
  //     }

  //     const userId = user._id;

  //     hitApi
  //       .post(`/news/save/`, {
  //         user_id: userId,
  //         news_id: newsId,
  //         ACTION: newsByCategories.isSaved ? "DELETE" : "SAVE",
  //       })
  //       .then((res) => {
  //         setShowModal(true);
  //         setTimeout(() => setShowModal(false), 3000);
  //       })
  //       .catch((error) => {
  //         console.error("Error:", error.message);
  //       });
  //   };

  const toggleSource = (source) => {
    setSelectedSources(source === "All" ? [] : [source]);
    setSourceSelected(true);
    // Don't reset sort option when changing source
    setTimeOptionSelected("");
    setActiveTimeOption("");
  };

  // Modify clear buttons handlers
  const handleClearSort = () => {
    setSourceSelected(false);
    setSortOptionSelected(false);
    setSortOption("By Default");
    setActiveSortOption("");
    // Reset time filters as they depend on sort being selected
    setTimeOptionSelected("");
    setActiveTimeOption("");
  };

  const handleClearTime = () => {
    setSortOptionSelected(false); // Keep sort options visible
    setTimeOptionSelected("");
    setActiveTimeOption("");
  };

  const buttons = [
    { filter: "All", name: "All News", id: "source-all-news" },
    {
      filter: "Kathmandu Post",
      name: "Kathmandu Post",
      id: "source-kathmandu-post",
    },
    {
      filter: "Annapurna Post",
      name: "Annapurna Post",
      id: "source-annapurna-post",
    },
    { filter: "Nagarik News", name: "Nagarik News", id: "source-nagarik-news" },
    { filter: "Gorkhapatra", name: "Gorkhapatra", id: "source-gorkhapatra" },
    {
      filter: "Makalu Khabar Nepali",
      name: "Makalu Khabar Nepali",
      id: "source-makalu-khabar-nepali",
    },
    {
      filter: "Makalu Khabar English",
      name: "Makalu Khabar English",
      id: "source-makalu-khabar-english",
    },
    {
      filter: "Setopati Nepali",
      name: "Setopati Nepali",
      id: "source-setopati-nepali",
    },
    {
      filter: "Setopati English",
      name: "Setopati English",
      id: "source-setopati-english",
    },
    {
      filter: "Himalaya Times",
      name: "Himalaya Times",
      id: "source-himalaya-times",
    },
    {
      filter: "Karobar Daily",
      name: "Karobar Daily",
      id: "source-karobar-daily",
    },
    { filter: "Image Khabar", name: "Image Khabar", id: "source-image-khabar" },
    { filter: "Ratopati", name: "Ratopati", id: "source-ratopati" },
    {
      filter: "Online Khabar English",
      name: "Online Khabar English",
      id: "source-online-khabar-english",
    },
    {
      filter: "Online Khabar Nepali",
      name: "Online Khabar Nepali",
      id: "source-online-khabar-nepali",
    },
    // {
    //   filter: "The Himalayan Times",
    //   name: "The Himalayan Times",
    //   id: "source-the-himalayan-times",
    // },
    {
      filter: "Rajdhani Daily",
      name: "Rajdhani Daily",
      id: "source-rajdhani-daily",
    },
    { filter: "News 24", name: "News 24", id: "source-news-24" },
    { filter: "Techmandu", name: "Techmandu", id: "source-techmandu" },
    {
      filter: "News of Nepal",
      name: "News of Nepal",
      id: "source-news-of-nepal",
    },
    { filter: "Nepal News", name: "Nepal News", id: "source-nepal-news" },
    {
      filter: "The Rising Nepal",
      name: "The Rising Nepal",
      id: "source-the-rising-nepal",
    },
    // {
    //   filter: "Hamro Khelkud",
    //   name: "Hamro Khelkud",
    //   id: "source-hamro-khelkud",
    // },
    { filter: "Himal Khabar", name: "Himal Khabar", id: "source-himal-khabar" },
    { filter: "Drishti News", name: "Drishti News", id: "source-drishti-news" },
    {
      filter: "Reporters Nepal",
      name: "Reporters Nepal",
      id: "source-reporters-nepal",
    },
    { filter: "Khabarhub", name: "Khabarhub", id: "source-khabarhub" },
    { filter: "Ajako Artha", name: "Ajako Artha", id: "source-ajako-artha" },
    { filter: "BIZMANDU", name: "Bizmandu", id: "source-bizmandu" },
    { filter: "Clickmandu", name: "Clickmandu", id: "source-clickmandu" },
    { filter: "Baahrakhari", name: "Baahrakhari", id: "source-baahrakhari" },
    { filter: "Nepal Press", name: "Nepal Press", id: "source-nepal-press" },
    { filter: "Kendrabindu", name: "Kendrabindu", id: "source-kendrabindu" },
  ];

  const sortButtons = [
    { id: "by-default", sortOption: "By Default", label: "By Default" },
    { id: "by-title", sortOption: "title", label: "Title" },
    {
      id: "by-date-newest",
      sortOption: "dateNewest",
      label: "Date (Newest First)",
    },
    {
      id: "by-date-oldest",
      sortOption: "dateOldest",
      label: "Date (Oldest First)",
    },
  ];

  const timeButtons = [
    {
      id: "within-the-hour",
      timeOption: "Within the hour",
      label: "Within the hour",
    },
    { id: "last-2-hours", timeOption: "Last 2 hours", label: "Last 2 hours" },
    {
      id: "last-24-hours",
      timeOption: "Last 24 hours",
      label: "Last 24 hours",
    },
    {
      id: "last-48-hours",
      timeOption: "Last 48 hours",
      label: "Last 48 hours",
    },
  ];

  buttons.sort((a, b) => a.name.localeCompare(b.name));
  sortButtons.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="mt-20 flex flex-col justify-center items-center">
      <div className="w-full p-2 font-Comfortaa">
        {/* BREADCRUMBS */}
        <div className="flex justify-center w-full rounded-sm bg-zinc-100 dark:bg-zinc-800">
          <p className="text-[14px] font-semibold text-zinc-900 dark:text-zinc-200 flex subpixel-antialiased px-2">
            <Link
              to="/home"
              className="flex items-center gap-2 p-1 xxs:text-xs md:text-sm"
            >
              <Home className="w-4 h-4" />
              <span className="xl:mt-1">Home</span>
            </Link>
            <span className="p-2">
              <Minus
                size={32}
                className="text-parewacreamcanprimary-100 dark:text-parewacreamcanprimary-100"
              />
            </span>
            <Link
              to={`/news?type=${encodedType}`}
              className="flex items-center gap-2 p-2 xxs:text-xs md:text-sm"
            >
              <Tag className="h-4 w-4" />
              <span className="xl:mt-1">{ToTitleCase(type)}</span>
            </Link>
          </p>
        </div>
        {/* BREADCRUMBS */}
        {/* SORTING OPTIONS */}
        <div className="flex justify-start w-full rounded-lg">
          <div className="flex-col font-Comfortaa font-bold p-1 w-full">
            {/* According to SOURCE */}
            <div className="w-full flex dark:border-zinc-900">
              <div className="flex xxs:flex-col lg:flex-row justify-start xxs:items-start lg:items-center xxs:gap-0 lg:gap-2 w-full ">
                <span className="sorting-category-label">Select Source</span>
                <div className="sorting-category-buttons">
                  {buttons.map((button, index) => (
                    <FilterButtons
                      key={index}
                      id={button.filter}
                      onClick={(e) => {
                        toggleSource(button.filter);
                        setSourceSelected(true);
                        handleClick(e, setActiveSource);
                      }}
                      active={activeSource}
                    >
                      <span className="w-60 flex xxs:justify-start lg:justify-center items-center">
                        {sourceImages[button.name] && (
                          <img
                            src={sourceImages[button.name]}
                            alt={button.name}
                            className="w-6 h-6 mr-2 rounded-full object-cover"
                          />
                        )}
                        {button.name}
                      </span>
                    </FilterButtons>
                  ))}
                </div>
              </div>
            </div>
            {/* According to SOURCE */}

            {/*According to SORTING OPTIONS */}
            {sourceSelected && (
              <div className="w-full flex xxs:flex-col 2xl:flex-row xxs:justify-start 2xl:justify-center items-center">
                <div className="w-full flex dark:border-zinc-900">
                  <div className="sorting-category-container">
                    <span className="sorting-category-label">Sort By</span>
                    <div className="sorting-category-buttons">
                      {sortButtons.map((button, index) => (
                        <FilterButtons
                          key={index}
                          id={button.id}
                          onClick={(e) => {
                            setSortOption(button.sortOption);
                            setSortOptionSelected(true);
                            handleClick(e, setActiveSortOption);
                          }}
                          active={activeSortOption}
                        >
                          <span className="xxs:w-48 lg:w-52 flex xxs:justify-start lg:justify-center xxs:text-xs md:text-sm xl:text-base">
                            {button.label}
                          </span>
                        </FilterButtons>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex xxs:flex-col ml-4 items-center p-1">
                  <button
                    onClick={() => {
                      handleClearSort();
                    }}
                    className="text-[10px] text-black dark:text-white hover:underline"
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
            {/*According to SORTING OPTIONS */}

            {/* According to TIME */}
            {sortOptionSelected && (
              <div className="flex w-full xxs:flex-col 2xl:flex-row xxs:justify-start 2xl:justify-center items-center">
                <div className="border-b w-full dark:border-zinc-900">
                  <div className="sorting-category-container">
                    <span className="sorting-category-label">Time</span>
                    <div className="sorting-category-buttons">
                      {timeButtons.map((button, index) => (
                        <FilterButtons
                          key={index}
                          id={button.id}
                          onClick={(e) => {
                            setTimeOptionSelected(button.timeOption);
                            handleClick(e, setActiveTimeOption);
                          }}
                          active={activeTimeOption}
                        >
                          <span className="xxs:w-48 lg:w-52 flex xxs:justify-start lg:justify-center xxs:text-xs md:text-sm xl:text-base">
                            {button.label}
                          </span>
                        </FilterButtons>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex xxs:flex-col ml-4 items-center p-1">
                  <button
                    onClick={() => {
                      handleClearTime();
                    }}
                    className="text-[10px] text-black dark:text-white hover:underline"
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
            {/* According to TIME */}
          </div>
        </div>
        {/* SORTING OPTIONS */}
      </div>

      <div className="grid-container-newspaper">
        <div className="grid-newspaper">
          {newsByCategories.length > 0 ? (
            newsByCategories.map((article, index) => (
              <div
                key={index}
                className="border dark:border-zinc-900 dark:bg-zinc-800 h-fit w-[300px] shadow-md rounded-lg p-3 hover:shadow-lg flex flex-col justify-between relative"
                style={{
                  gridRowEnd: `span ${article.image_source ? 2 : 1}`,
                }}
              >
                {likedArticleId === article._id && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        delay: 0.2,
                        type: "spring",
                        stiffness: 60,
                        damping: 10,
                        duration: 0.8,
                        ease: "easeInOut",
                      }}
                      className="p-4 rounded-lg text-white"
                    >
                      <HeartFill size={36} />
                    </motion.div>
                  </div>
                )}
                <div className="w-full h-fit">
                  {article.source_name !== "Annapurna Post" &&
                    article.source_name !== "Kathmandu Post" &&
                    article.source_name !== "Ajako Artha" && (
                      <div className="flex md:flex-col lg:flex-row items-center pb-2">
                        <Time size={15} className="mr-1 dark:text-zinc-300" />
                        <span className="text-xs tracking-wide dark:text-white">
                          {moment(article.pub_date)
                            .locale("ne")
                            .format("MMMM Do YYYY, h:mm:ss a")}
                        </span>
                      </div>
                    )}

                  {article.image_source && (
                    <Suspense fallback={<Loading />}>
                      <LazyImage
                        src={article.image_source}
                        alt="img"
                        className="rounded-sm hover:scale-105 hover:transition-all hover:duration-300 hover:ease-in-out aspect-square w-full max-h-full object-cover object-center"
                      />
                    </Suspense>
                  )}

                  <div className={`${article.image_source ? "mt-2" : "mt-4"}`}>
                    <h3 className="font-semibold xxs:text-sm sm:text-lg w-fit dark:text-white px-1">
                      {article.title
                        ? new DOMParser()
                            .parseFromString(article.title, "text/html")
                            .body.textContent.split(" ")
                            .slice(0, 20)
                            .join(" ")
                        : ""}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-wrap justify-between items-center p-1 my-6">
                  <p className="text-sm text-gray-600 dark:text-gray-300 flex gap-2 font-Comfortaa items-center font-thin">
                    <img
                      src={sourceImages[article.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>
                    {article.source_name}
                  </p>
                </div>

                <div>
                  <a
                    className="news-card-learn-more-button w-fit rounded-lg ml-1"
                    href={`/news/${article._id}`}
                  >
                    Learn More
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                  <CardButtons
                    setButtonName={setButtonName}
                    news={article}
                    handlePopUpLike={() => handlePopUpLike(article._id)}
                    setShowLoginAlert={setShowLoginAlert}
                    className="flex justify-between my-2 dark:text-gray-600"
                  />
                </div>
              </div>
            ))
          ) : (
            <motion.div
              className="title flex justify-center items-center flex-col w-full"
              initial={{ y: -200 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.5, type: "spring", stiffness: 120 }}
            >
              No news in this range
              <p className="text-xs mt-2 dark:text-white text-zinc-400 normal-case">
                Try different parameters
              </p>
            </motion.div>
          )}
        </div>
      </div>
      {/* Show More Button */}
      {currentPage < totalPages && (
        <div className="w-full mt-4 p-2 mb-2 rounded-lg font-Comfortaa">
          <div className="flex justify-center">
            <button
              onClick={handleShowMore}
              className="px-4 py-2 bg-zinc-100 dark:bg-zinc-900 bg-gradient-to-t from-zinc-100 dark:from-zinc-800 to-zinc-50 dark:to-zinc-900 shadow-lg text-black dark:text-white rounded-full hover:bg-opacity-50 hover:text-opacity-25 dark:hover:bg-opacity-50 dark:hover:text-opacity-25"
            >
              Show More
            </button>
          </div>
        </div>
      )}
      {/* Show More Button */}

      {/* BODY */}
      {showModal && (
        <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
          <SuccessfulAlert
            buttonName={buttonName}
            setShowModal={setShowModal}
          />
        </div>
      )}
      {showLoginAlert && (
        <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
          <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
            <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
              You are about to{" "}
              <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                {buttonName}
              </span>{" "}
              a news article
            </div>
            <LoginModal setShowLoginAlert={setShowLoginAlert} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsByCategories;
