import { Form, Formik } from "formik";
import { useState } from "react";
import { PiEye, PiEyeSlash } from "react-icons/pi";
import * as yup from "yup";
import FormikInput from "../Form/FormikInput.jsx";
import PasswordValidation from "./PasswordValidation.jsx";

const SignUp = ({
  data,
  setData,
  setError,
  handlePasswordChange,
  isTypingPassword,
  password,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordEye = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordEye = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const initialValues = {
    username: data.username || "",
    email: data.email || "",
    password: data.password || "",
    confirmPassword: data.confirmPassword || "",
  };

  const validationSchema = yup.object({
    username: yup.string().required("Username is required"),
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <div className="space-y-6 w-full">
      <div className="w-full xxs:p-4 sm:p-6">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                {/* USERNAME Section */}
                <div className="">
                  <label className="antialiased block xxs:text-xs sm:text-sm text-zinc-700 pb-2 font-extrabold">
                    Username
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-zinc-100 py-2 px-3 placeholder:text-zinc-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="username"
                    type="text"
                    placeholder="Username"
                    onChange={(event) => {
                      setData({ ...data, username: event.target.value });
                      setFieldValue("username", event.target.value);
                      setError(""); // Clear the error message
                      // console.log("heelllooo");
                    }}
                    required={true}
                    value={data.username}
                  ></FormikInput>
                </div>

                {/* Email Section */}
                <div className="mt-4">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-zinc-700 pb-2">
                    Email
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-zinc-100 py-2 px-3 placeholder:text-zinc-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={(event) => {
                      setData({ ...data, email: event.target.value });
                      setFieldValue("email", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.email}
                  ></FormikInput>
                </div>

                {/* Password Section */}
                <div className="mt-4 relative">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-zinc-700 pb-2">
                    Password
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-zinc-100 py-2 px-3 placeholder:text-zinc-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    onChange={(event) => {
                      handlePasswordChange(event);
                      setData({ ...data, password: event.target.value });
                      setFieldValue("password", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.password}
                  ></FormikInput>
                  <div className="text-2xl absolute top-1/2 right-5">
                    {showPassword ? (
                      <PiEyeSlash onClick={togglePasswordEye} />
                    ) : (
                      <PiEye onClick={togglePasswordEye} />
                    )}
                  </div>
                </div>

                {isTypingPassword && <PasswordValidation password={password} />}

                {/* Confirm Password Section */}
                <div className="mt-4 relative">
                  <label className="antialiased block xxs:text-xs sm:text-sm font-extrabold text-zinc-700 pb-2">
                    Confirm Password
                  </label>
                  <FormikInput
                    className="block h-14 w-full appearance-none bg-zinc-200 rounded-lg border border-zinc-100 py-2 px-3 placeholder:text-zinc-400 shadow-sm focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 xxs:text-xs sm:text-sm"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    onChange={(event) => {
                      setData({ ...data, confirmPassword: event.target.value });
                      setFieldValue("confirmPassword", event.target.value);
                      setError(""); // Clear the error message
                    }}
                    required={true}
                    value={data.confirmPassword}
                  ></FormikInput>
                  <div className="text-2xl absolute top-1/2 right-5">
                    {showConfirmPassword ? (
                      <PiEyeSlash onClick={toggleConfirmPasswordEye} />
                    ) : (
                      <PiEye onClick={toggleConfirmPasswordEye} />
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default SignUp;
