import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUserDetails, setToken } from "../slice/authSlice.js";

const useToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxToken = useSelector((state) => state.auth.token);

  const getToken = () => {
    if (reduxToken) {
      return reduxToken;
    } else {
      return localStorage.getItem("token") || sessionStorage.getItem("token");
    }
  };

  const [token, setTokenState] = useState(getToken());

  useEffect(() => {
    const tokenFromStorage = getToken();
    if (tokenFromStorage && !reduxToken) {
      dispatch(setToken(tokenFromStorage));
      const userId = getUserIdFromToken(tokenFromStorage);
      if (userId) {
        dispatch(fetchUserDetails({ token: tokenFromStorage, userId }));
      }
    }
    setTokenState(tokenFromStorage);
  }, [reduxToken, dispatch]);

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        console.log("Token expired");
        toast.error("Session expired. Please login again.");
        localStorage.clear();
        sessionStorage.clear();
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Redirect after 3 seconds
      }
    }
  }, [token, navigate]);

  const getUserIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      // console.log(decodedToken);
      let userId = decodedToken._id;
      return userId;
    } catch (error) {
      console.error("Error decoding token:", error.message);
      return null;
    }
  };

  return token;
};

export default useToken;
