import { hitApi } from "../components/services/hitApi.js";
import { removeSavedNews, setShowModal } from "../slice/authSlice.js";
import { updateLikedNews } from "../slice/likedNewsSlice.js";

/**
 * Save a news article for a user
 * @param {string} newsId - The ID of the news to save
 * @param {string} userId - The ID of the user
 * @param {string} token - Authentication token
 * @param {function} setIsSaved - State setter function for saved status
 * @param {function} dispatch - Redux dispatch function
 * @param {function} setShowLoginAlert - Function to show login alert for unauthenticated users
 */
export const saveNews = async (
  newsId,
  userId,
  token,
  setIsSaved,
  dispatch,
  openLoginPrompt
) => {
  if (!token || !userId) {
    // Call the new login prompt function instead of setting a flag
    openLoginPrompt("Save");
    return;
  }

  // Update local state immediately for responsive UI
  setIsSaved(true);

  try {
    await hitApi.post(
      `/users/news/save-activity/`,
      {
        user_id: userId,
        news_id: newsId,
        ACTION: "SAVE",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Show success notification
    dispatch(setShowModal(true));
    setTimeout(() => dispatch(setShowModal(false)), 3000);
  } catch (error) {
    console.error("Error saving news:", error.message);
    // Revert local state if API call fails
    setIsSaved(false);
  }
};

/**
 * Delete a saved news article for a user
 * @param {string} newsId - The ID of the news to delete
 * @param {string} userId - The ID of the user
 * @param {string} token - Authentication token
 * @param {function} setIsSaved - State setter function for saved status
 * @param {function} dispatch - Redux dispatch function
 * @param {function} setShowLoginAlert - Function to show login alert for unauthenticated users
 */
export const deleteSavedNews = async (
  newsId,
  userId,
  token,
  setIsSaved,
  dispatch,
  openLoginPrompt
) => {
  if (!token || !userId) {
    // Call the new login prompt function instead of setting a flag
    openLoginPrompt("Delete");
    return;
  }

  try {
    // Update local state immediately for responsive UI
    setIsSaved(false);

    await hitApi({
      method: "DELETE",
      url: `/users/news/save-activity/${userId}`,
      data: { news_id: newsId, user_id: userId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Update Redux state
    dispatch(removeSavedNews(newsId));
  } catch (error) {
    console.error("Error removing saved news:", error.message);
    // Revert local state if API call fails
    setIsSaved(true);
  }
};

/**
 * Like or dislike a news article
 * @param {string} newsId - The ID of the news to like/dislike
 * @param {string} userId - The ID of the user
 * @param {string} token - Authentication token
 * @param {string} action - The action to perform (LIKE, UNLIKE, DISLIKE, UNDISLIKE)
 * @param {function} setLikedStatus - State setter function for liked status
 * @param {function} dispatch - Redux dispatch function
 * @param {function} setShowLoginAlert - Function to show login alert for unauthenticated users
 * @param {function} handlePopUpLike - Function to show like popup animation
 */
export const handleLikeAction = async (
  newsId,
  userId,
  token,
  action,
  setLikedStatus,
  dispatch,
  setShowLoginAlert,
  handlePopUpLike
) => {
  if (!userId) {
    setShowLoginAlert(true);
    return;
  }

  // Update local state immediately for responsive UI
  setLikedStatus(
    action === "LIKE" ? "LIKE" : action === "DISLIKE" ? "DISLIKE" : null
  );

  if (action === "LIKE" && handlePopUpLike) {
    handlePopUpLike(true);
  }

  try {
    await hitApi({
      method: "POST",
      url: `/users/news/like-activity`,
      data: { user_id: userId, news_id: newsId, action },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Show success notification
    dispatch(setShowModal(true));

    // Update Redux state
    dispatch(updateLikedNews({ newsId, action }));

    setTimeout(() => dispatch(setShowModal(false)), 3000);
  } catch (error) {
    console.error("Error updating liked news:", error.message);
    // No need to revert since we don't know the previous state
  }
};
