import React from "react";
import HeroVerifyLLM from "./HeroVerify/HeroVerifyLLM.jsx";

// const customSwalStyles = Swal.mixin({
//   customClass: {
//     container: "font-Comfortaa",
//     popup: "rounded-2xl shadow-2xl",
//     title: "text-2xl font-bold text-gray-800 mb-4",
//     htmlContainer: "text-base text-gray-600",
//     confirmButton:
//       "bg-zinc-600 hover:bg-zinc-700 text-white font-semibold py-2 px-6 rounded-lg transition-all duration-200",
//     cancelButton:
//       "bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-6 rounded-lg transition-all duration-200",
//     timerProgressBar: "bg-parewacreamcanprimary-200 h-2 rounded-lg",
//   },
//   buttonsStyling: false,
// });

const VerifyPageNew = () => {
  // const [source, setSource] = useState({
  //   platform: "",
  //   customSource: "",
  //   url: "",
  // });
  // const [title, setTitle] = useState("");
  // const [description, setDescription] = useState("");
  // const [email, setEmail] = useState("");

  // const [createVerification, { isLoading, isError, error }] =
  //   useCreateVerificationMutation();

  // useEffect(() => {
  //   if (isError) {
  //     Swal.fire({
  //       title: "Error!",
  //       text: error?.data?.message || "Something went wrong",
  //       icon: "error",
  //       showConfirmButton: true,
  //       draggable: true,
  //     });
  //   }
  // }, [isLoading, isError, error]);

  // const handleSubmit = async () => {
  //   try {
  //     const formData = {
  //       source,
  //       title,
  //       description,
  //       email,
  //     };

  //     // Validation check
  //     if (
  //       !source.platform ||
  //       !title.trim() ||
  //       !description.trim() ||
  //       !email.trim() ||
  //       (source.platform === "Other" && !source.customSource.trim())
  //     ) {
  //       customSwalStyles.fire({
  //         title: "Please fill all the required fields",
  //         text: "All fields are required to process your verification request",
  //         icon: "warning",
  //         showConfirmButton: true,
  //         draggable: true,
  //         timer: 3000,
  //         timerProgressBar: true,
  //         allowOutsideClick: false,
  //       });
  //       return;
  //     }

  //     await createVerification(formData).unwrap();

  //     // Success message
  //     customSwalStyles.fire({
  //       title: "Thank you for Contributing!",
  //       html: `
  //       <div class="space-y-4">
  //         <p class="text-zinc-700">We are committed to ensuring accurate news reaches everyone.</p>
  //         <div class="bg-blue-50 p-4 rounded-lg">
  //           <p class="text-blue-800">A detailed verification report will be sent to once our servers are done verifying:</p>
  //           <p class="font-semibold text-black">${email}</p>
  //         </div>
  //         <p class="text-zinc-700 italic"><span class="text-parewacreamcanprimary-200 font-bold">Together</span>, we are fostering a more informed and trustworthy digital landscape.</p>
  //       </div>
  //     `,
  //       icon: "success",
  //       confirmButtonText: "Great!",
  //       draggable: true,
  //       showCloseButton: true,
  //       allowOutsideClick: false,
  //       timer: 10000, // 5 seconds
  //       timerProgressBar: true,
  //       // didOpen: (popup) => {
  //       //   console.log("Alert opened:", popup);
  //       // },
  //       willClose: () => {
  //         // Clear form after alert closes
  //         setSource({
  //           platform: "",
  //           customSource: "",
  //           url: "",
  //         });
  //         setTitle("");
  //         setDescription("");
  //         setEmail("");
  //       },
  //     });
  //   } catch (error) {
  //     customSwalStyles.fire({
  //       title: "Verification Request Failed",
  //       text:
  //         error?.data?.message ||
  //         "Unable to process your request. Please try again.",
  //       icon: "error",
  //       showConfirmButton: true,
  //       draggable: true,
  //       // timer: 3000,
  //       // timerProgressBar: true,
  //       allowOutsideClick: false,
  //     });
  //   }
  // };

  return (
    <div className=" w-full h-full flex flex-col xxs:mt-7 sm:mt-16 font-Comfortaa">
      {/* <HeroVerify
        source={source}
        setSource={setSource}
        setTitle={setTitle}
        setDescription={setDescription}
        title={title}
        description={description}
        email={email}
        setEmail={setEmail}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      /> */}
      <HeroVerifyLLM />
      {/* <Statistic /> */}
    </div>
  );
};

export default VerifyPageNew;
