import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hitApi } from "../components/services/hitApi";

const useUserActivities = (userId, token, shouldFetch) => {
  const [likeActivities, setLikeActivities] = useState([]);
  // const [saveActivities, setSavedActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchActivitiesAndLikes = async () => {
      if (!shouldFetch || !userId || !token) {
        setLoading(false);
        return;
      }

      try {
        const response = await hitApi.get(
          `/users/news/like-activity/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { likeActivities } = response.data?.data;

        setLikeActivities(likeActivities);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching activities and likes:", error);
        setLoading(false);
      }
    };

    // const fetchActivitiesAndSaved = async () => {
    //   if (!shouldFetch || !userId || !token) {
    //     setLoading(false);
    //     return;
    //   }

    //   try {
    //     const response = await hitApi.get(
    //       `/users/news/save-activity/${userId}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );
    //     const { savedNews } = response.data?.data;

    //     setSavedActivities(savedNews);
    //     setLoading(false);
    //   } catch (error) {
    //     console.error("Error fetching activities and saved news:", error);
    //     setLoading(false);
    //   }
    // };

    fetchActivitiesAndLikes();
    // fetchActivitiesAndSaved();
  }, [userId, token, shouldFetch, dispatch]);

  return { likeActivities, loading };
};

export default useUserActivities;
