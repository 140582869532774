import { useState, useEffect } from "react";
import { hitApi } from "../components/services/hitApi";

const useUserImage = (userId, token, shouldFetch) => {
  const [userImage, setUserImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserImage = async () => {
      if (!shouldFetch || !userId || !token) {
        setLoading(false);
        return;
      }

      try {
        const response = await hitApi.get(`/userimage/${userId}`, {
          responseType: "blob",
        });
        if (response.request.responseURL) {
          setUserImage(response.request.responseURL);
        } else {
          const imageBlob = response.data;
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setUserImage(imageObjectURL);
        }
        setLoading(false);
      } catch (error) {
        console.log("Error getting Image here!", error);
        setLoading(false);
      }
    };

    fetchUserImage();
  }, [userId, token, shouldFetch]);

  return { userImage, loading };
};

export default useUserImage;
