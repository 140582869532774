import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { motion } from "framer-motion";
import { ArrowUpRightFromCircle, BadgeHelp, MoreVertical } from "lucide-react";
import { Suspense, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import sourceImages from "../../../utils/sourceImages.js";
import Loading from "../../Loading.jsx";
import CardButtons from "../CardButtons/CardButtons.jsx";
import LazyImage from "../LazyImage.jsx";
import { ToTitleCase } from "./utils/ToTitleCase.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsWithImage = ({
  news,
  setShowLoginAlert,
  setButtonName,
  buttonName,
  toggleSourceName,
}) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [showLikePopup, setShowLikePopup] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const goToNewsSource = () => {
    const a = document.createElement("a");
    a.href = news.source_url;
    const baseUrl = `${a.protocol}//${a.hostname}`;
    window.open(baseUrl, "_blank");
  };

  const verifyPage = () => {
    navigate("/verify-page");
  };

  const handlePopUpLike = (action) => {
    setShowLikePopup(true);
    setTimeout(() => {
      setShowLikePopup(false);
    }, 1000);
  };

  const handleCardClick = (e) => {
    // Navigate to news detail page
    navigate(`/news/${news?._id}`);
  };

  const handleSpecificClick = (e) => {
    // Prevent clicks within these elements from triggering the card navigation
    e.stopPropagation();
  };

  return (
    <div className="relative h-full">
      <div
        key={news._id}
        className="flex w-full h-full bg-zinc-100 rounded-lg dark:bg-zinc-800 relative cursor-pointer"
        onClick={handleCardClick}
      >
        {/* Background Image */}
        {news.image_source && (
          <div className="absolute inset-0 w-full h-full rounded-lg">
            <Suspense fallback={<Loading />}>
              <LazyImage
                news={news}
                src={news.image_source}
                alt=""
                className="h-full w-full object-cover object-center rounded-lg"
              />
            </Suspense>
            {/* Semi-transparent base overlay for better text readability */}
            <div className="absolute inset-0 bg-black/40 rounded-lg"></div>
            {/* Gradient Overlay - Top to Middle */}
            <div className="absolute inset-x-0 top-0 h-1/2 bg-gradient-to-b from-black/80 to-transparent rounded-t-lg"></div>
            {/* Gradient Overlay - Bottom to Middle */}
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-black/90 to-transparent rounded-b-lg"></div>
          </div>
        )}

        {showLikePopup && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25 z-10 rounded-lg">
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 60,
                damping: 10,
                duration: 0.8,
                ease: "easeInOut",
              }}
              className="p-4 rounded-lg text-white"
            >
              <HeartFill size={36} />
            </motion.div>
          </div>
        )}
        <article
          id={`news-${news.source_name}`}
          className="flex xxs:flex-col lg:flex-row w-full h-full relative z-2 p-2"
        >
          <div className="flex flex-col justify-around w-full h-full">
            {/* TITLE AND DESCRIPTION */}
            <div className="flex flex-col h-full w-full">
              <div className="flex items-center justify-between">
                <span className="m-2 w-fit news-card-category-body text-white">
                  {ToTitleCase(news.type || news.category[0] || "Thinking")}
                </span>
                <div className="mr-4" onClick={handleSpecificClick}>
                  <div className="relative flex" ref={dropdownRef}>
                    <button
                      className="hover:text-zinc-100 dark:hover:text-parewacreamcanprimary-100 cursor-pointer text-zinc-300"
                      title="More Options"
                      onClick={() => setShowDropdown(!showDropdown)}
                    >
                      <MoreVertical size={24} strokeWidth={2} />
                    </button>

                    {showDropdown && (
                      <div className="origin-top-right z-50 absolute top-2 right-0 mt-2 w-56 rounded-md shadow-xl bg-zinc-100 dark:bg-zinc-800 border-black rounded-lg ring-1 ring-black ring-opacity-5">
                        <div
                          className="p-2"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          <button
                            className="px-4 py-2 text-sm flex gap-2 items-center text-zinc-700 w-full dark:text-zinc-400 dark:hover:bg-zinc-700 dark:hover:bg-opacity-50 hover:bg-zinc-200 rounded-lg"
                            role="menuitem"
                            onClick={goToNewsSource}
                          >
                            <ArrowUpRightFromCircle size={15} />
                            <span className="text-[11px] tracking-wider font-semibold p-1">
                              {`Go to ${news.source_name}`}
                            </span>
                          </button>

                          <button
                            className="flex gap-2 px-4 py-2 text-sm items-center dark:hover:text-zinc-900 text-zinc-700 w-full dark:text-zinc-400 dark:hover:bg-green-200 dark:hover:bg-opacity-60 hover:bg-green-200 rounded-lg"
                            role="menuitem"
                            onClick={verifyPage}
                          >
                            <BadgeHelp size={15} />{" "}
                            <span className="text-[11px] tracking-wider font-semibold p-1">
                              Verify Page
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full p-2">
                <h3 className="flex news-card-withimg-title">
                  {news.title
                    ? new DOMParser()
                        .parseFromString(news.title, "text/html")
                        .body.textContent.split(" ")
                        .slice(0, 10)
                        .join(" ")
                    : ""}
                </h3>

                <p className="mt-4 2xl:h-40 xxs:h-20 tracking-wider news-card-withimg-description">
                  {news.description
                    ? new DOMParser()
                        .parseFromString(news.description, "text/html")
                        .body.textContent.slice(0, 70) + "..."
                    : ""}
                </p>
              </div>
            </div>
            {/* TITLE AND DESCRIPTION */}

            <div className="flex flex-col md:flex-col w-full justify-between font-Comfortaa">
              {/* Published Time and News Source */}
              <div className="items-center w-full px-5 mx-auto py-2">
                <div className="flex justify-between">
                  <div className="flex cursor-vertical-text p-1 items-center gap-2">
                    {news.source_name !== "Annapurna Post" &&
                      news.source_name !== "Kathmandu Post" &&
                      news.source_name !== "Ajako Artha" && (
                        <div className="flex-col">
                          <p className="text-xs text-gray-300">
                            {new Date(news.pub_date).toLocaleTimeString(
                              "en-US",
                              {
                                hour: "2-digit",
                                minute: "2-digit",
                              }
                            )}
                          </p>
                          <p className="xxs:text-[9px] lg:text-[10px] font-light text-gray-300">
                            Time
                          </p>
                        </div>
                      )}
                    <div className="flex-col">
                      <p className="text-xs text-gray-300">
                        {new Date(news.pub_date).toLocaleDateString()}
                      </p>
                      <p className="xxs:text-[9px] lg:text-[10px] font-light text-gray-300">
                        Date
                      </p>
                    </div>
                  </div>

                  <div
                    className="flex p-1 overflow-hidden"
                    onClick={handleSpecificClick}
                  >
                    <p className="xxs:flex justify-end text-[12px] text-white items-center">
                      <img
                        src={sourceImages[news.source_name] || parewaImg}
                        width={25}
                        height={25}
                        className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                        alt="source_img"
                      ></img>

                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSourceName(news.source_name);
                        }}
                        className="news-card-withimg-source ml-2 cursor-pointer"
                      >
                        {news.source_name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              {/* Published Time and News Source */}

              {/* BUTTONS AND READ MORE */}
              <div onClick={handleSpecificClick}>
                <CardButtons
                  handlePopUpLike={handlePopUpLike}
                  news={news}
                  setShowLoginAlert={setShowLoginAlert}
                  setButtonName={setButtonName}
                  buttonName={buttonName}
                  parentComponent="NewsWithImage"
                />
              </div>
              {/* BUTTONS AND READ MORE */}
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default NewsWithImage;
