import { Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogin } from "../../../utils/loginHelper";
import LoginForm from "../../Form/LoginForm/LoginForm";
import SuccessfulModal from "../../LoginPage/Modals/SuccessfulModal";

const NewLoginModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // If no external control is provided (backward compatibility)
  const [internalOpen, setInternalOpen] = useState(false);

  // Determine which open state to use
  const open = isOpen !== undefined ? isOpen : internalOpen;

  const showInternalModal = () => {
    setInternalOpen(true);
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await handleLogin({
      values,
      onSubmitProps,
      dispatch,
      setIsLoading,
      setIsSuccess,
      setIsError,
      setShowModal,
      rememberMe,
      navigate,
    });
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
    } else {
      setInternalOpen(false);
    }
    setIsError(false);
  };

  return (
    <>
      {/* Only render button if no external control is provided */}
      {isOpen === undefined && (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={showInternalModal}
        >
          Login
        </button>
      )}

      <Modal
        title="Login to Your Account"
        open={open}
        onCancel={handleCancel}
        footer={null}
        centered
      >
        {/* Login Form */}
        <LoginForm
          buttonName="Login"
          isLoading={isLoading}
          onSubmit={handleFormSubmit}
          isSuccess={isSuccess}
          setRememberMe={setRememberMe}
        />

        {isError && (
          <div className="text-red-500 text-center mt-2">
            Invalid credentials. Please try again.
          </div>
        )}

        {/* Success Modal */}
        <SuccessfulModal open={showModal} onClose={() => setShowModal(false)}>
          <div className="bg-white p-4 rounded">
            <h2 className="text-lg font-bold text-center">Login Successful!</h2>
            {isLoading && <p className="text-center mt-2">Redirecting...</p>}
          </div>
        </SuccessfulModal>
      </Modal>
    </>
  );
};

export default NewLoginModal;
