import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { hitApi } from "../components/services/hitApi.js";
import { setSavedNewsInStore } from "../slice/authSlice.js";

const useFetchSavedNews = (token, userId) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const fetchSavedNews = useCallback(
    async (type = null) => {
      if (!token || !userId) return; // Ensure token and userId are present
      try {
        const url = type
          ? `/users/news/save-activity/${userId}/${type}`
          : `/users/news/save-activity/${userId}`;
        const response = await hitApi.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        dispatch(setSavedNewsInStore(response.data.news));
        setError(null);
      } catch (error) {
        console.error("Error fetching saved news:", error.message);
        setError(error.message);
      }
    },
    [token, userId, dispatch]
  );

  useEffect(() => {
    if (token && userId) {
      fetchSavedNews();
    }
  }, [token, userId, fetchSavedNews]);

  return { fetchSavedNews, error };
};

export default useFetchSavedNews;
