import { Link } from "react-router-dom";
import { Slash } from "@styled-icons/bootstrap/Slash";
import { useEffect, useState } from "react";
import { Home } from "@styled-icons/boxicons-solid/Home";
import { Copyright } from "@styled-icons/fa-regular/Copyright";
import { PrivacyTip } from "@styled-icons/material-outlined/PrivacyTip";
import { Handshake } from "@styled-icons/fa-regular/Handshake";
// import { Team } from "@styled-icons/remix-line/Team";
// import { Aboutdotme } from "@styled-icons/simple-icons/Aboutdotme";

const BreadcrumbItem = ({ icon: Icon, to, label, active }) => {
  return (
    <div className="flex items-center w-full">
      <Icon
        size={10}
        className="inline w-4 h-4 mx-2 text-parewacreamcanprimary-200"
      />

      <Link
        to={to}
        className={`${
          active === label
            ? "text-blue-500 dark:text-blue-300"
            : "text-zinc-600 dark:text-zinc-500"
        }`}
      >
        {label}
      </Link>
    </div>
  );
};

const Breadcrumbs = ({ crumb }) => {
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(crumb);
  }, [crumb]);

  const breadcrumbData = [
    // { icon: Home, to: "/", label: "Home" },
    { icon: PrivacyTip, to: "/privacy-policy", label: "Privacy Policy" },
    {
      icon: Handshake,
      to: "/terms-and-conditions",
      label: "Terms and Conditions",
    },
    {
      icon: Copyright,
      to: "/copyright-intellectual-property",
      label: "Copyright and Intellectual Property",
    },
    // {
    //   icon: Aboutdotme,
    //   to: "/about-us",
    //   label: "About Us",
    // },
    // {
    //   icon: Team,
    //   to: "/meet-team",
    //   label: "Meet the Team",
    // },
  ];

  return (
    <div className="xxs:w-full sm:max-w-5xl xxs:rounded-none md:rounded-lg shadow-sm mt-2 mb-2">
      {/* <div className="xxs:mt-4 sm:mt-10 xxs:w-full xxs:text-center xxs:text-[10px] sm:text-sm sm:text-left sm:max-w-4xl xxs:p-1 sm:p-2 h-fit text-sm dark:text-white flex xxs:flex-col sm:flex-row xxs:items-start sm:items-center"> */}
      <div className="p-4 flex flex-wrap items-center xxs:flex-col xxs:text-[13px] md:text-sm md:text-left md:flex-row h-fit w-full justify-center">
        <div className="flex items-center xxs:w-full md:w-fit">
          <Home
            size={10}
            className="inline w-4 h-4 mx-2 text-parewacreamcanprimary-200"
          />
          <Link to="/home" className="text-parewacreamcanprimary-200">
            Home
          </Link>
        </div>
        <div className="md:inline xxs:hidden">
          <Slash size={25} className="w-4 h-4 mx-2 dark:text-white" />
        </div>

        {breadcrumbData.map((item, index) => (
          <div
            key={item.label}
            className="flex items-center xxs:w-full md:w-fit"
          >
            <BreadcrumbItem
              icon={item.icon}
              to={item.to}
              label={item.label}
              active={active}
            />
            {index < breadcrumbData.length - 1 && (
              <div className="md:inline xxs:hidden">
                <Slash size={25} className="w-4 h-4 mx-2 dark:text-white" />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
