import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../../slice/authSlice";

const SuccesfulAlert = ({ buttonName }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const userId = user?._id;

  const handleClose = () => {
    dispatch(setShowModal(false));
  };

  return (
    <div
      className="flex items-center border-t-4 rounded-lg border border-zinc-200 dark:border-zinc-700 bg-zinc-100 dark:bg-zinc-900 p-4"
      role="alert"
    >
      <div className="flex items-center gap-4 justify-between w-full">
        <span className="text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>

        <div className="flex-1">
          <strong className="block xxs:text-xs lg:text-sm text-black dark:text-parewacreamcanprimary-200 tracking-wider font-Comfortaa">
            {" "}
            {buttonName + "d"} successfully!{" "}
          </strong>
          {buttonName === "Save" && (
            <p className="text-xs text-black dark:text-white tracking-wider font-Comfortaa">
              Go to{" "}
              <Link to={`/profile/${userId}`} className="underline">
                Saved
              </Link>
            </p>
          )}
        </div>

        <button
          className="text-zinc-500 transition hover:text-opacity-50"
          onClick={handleClose}
        >
          <span className="sr-only">Dismiss popup</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SuccesfulAlert;
