import { Bookmark } from "lucide-react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { hitApi } from "../../../components/services/hitApi.js";
import useToken from "../../../hooks/useToken.js";
import useUser from "../../../hooks/useUser.js";
import { setShowModal } from "../../../slice/authSlice.js";
import { updateLikedNews } from "../../../slice/likedNewsSlice.js";
import { showInstagramInstructions } from "../../../utils/instagramShare.js";
// Import SVGs as React components
import { ReactComponent as CopyLinkGray } from "../../../assets/icons/copylinkgrey-01.svg";
import { ReactComponent as CopyLinkWhite } from "../../../assets/icons/copylinkwhite-01.svg";
import { ReactComponent as CopyLinkYellow } from "../../../assets/icons/copylinkyellow-01.svg";
import { ReactComponent as HeartRed } from "../../../assets/icons/heartred-01.svg";
import { ReactComponent as HeartWhite } from "../../../assets/icons/heartyellow-01.svg";
import { ReactComponent as ShareIconGray } from "../../../assets/icons/sharegrey-01.svg";
import { ReactComponent as ShareIconWhite } from "../../../assets/icons/sharewhite-01.svg";
import { ReactComponent as SaveYellow } from "../../../assets/icons/saveyellow-01.svg";
import {
  deleteSavedNews,
  saveNews,
} from "../../../utils/newsInteractionHelpers.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaLogo = `${bucketUrl}/backend/assets/e-parewa_light.png`;

const CardButtons = ({
  news,
  setShowLoginAlert,
  setButtonName,
  handlePopUpLike,
  parentComponent,
}) => {
  const token = useToken();
  const { user } = useUser();
  const [likedStatus, setLikedStatus] = useState(null);
  const [isSaved, setIsSaved] = useState();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.auth.mode);
  const userId = user?._id;

  // Determine the saved status from the savedNews array
  useEffect(() => {
    if (user && user.savedNews) {
      const savedNewsItem = user.savedNews.find(
        (item) => item.news_id._id === news._id
      );
      setIsSaved(!!savedNewsItem);
    }
  }, [user, news._id]);

  // Determine the liked status from the likedNews array
  useEffect(() => {
    if (user && user.likedNews) {
      const likedNewsItem = user.likedNews.find(
        (item) => item.newsId._id === news._id
      );
      setLikedStatus(likedNewsItem ? likedNewsItem.action : null);
    }
  }, [user, news._id]);

  // Determine the disliked status from the dislikedNews array
  useEffect(() => {
    if (user && user.dislikedNews) {
      const dislikedNewsItem = user.dislikedNews.find(
        (item) => item.newsId._id === news._id
      );
      if (dislikedNewsItem) {
        setLikedStatus("DISLIKE");
      }
    }
  }, [user, news._id]);

  const handleLike = async (ACTION) => {
    if (!user) {
      // Changed this part to use the new openLoginPrompt function
      setShowLoginAlert(
        ACTION === "LIKE" ? "Like" : ACTION === "DISLIKE" ? "Dislike" : "Unlike"
      );
      return;
    }

    // Update local state immediately
    setLikedStatus(
      ACTION === "LIKE" ? "LIKE" : ACTION === "DISLIKE" ? "DISLIKE" : null
    );

    try {
      // Make an API call to update the liked news in the database
      await hitApi({
        method: "POST",
        url: `/users/news/like-activity`,
        data: { user_id: userId, news_id: news._id, action: ACTION },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Dispatch the setShowModal action to show the modal
      dispatch(setShowModal(true));
      // Update the Redux state after the API call is successful
      dispatch(updateLikedNews({ newsId: news._id, action: ACTION }));
      setTimeout(() => dispatch(setShowModal(false)), 3000);
    } catch (error) {
      console.error("Error updating liked news:", error.message);
      // Revert local state if API call fails
      setLikedStatus(news.liked);
    }
  };

  // Handle sharing functionality
  const handleShare = () => {
    const newsUrl = `${window.location.origin}/news/${news._id}`;
    const newsTitle = news.title
      ? new DOMParser().parseFromString(news.title, "text/html").body
          .textContent
      : "Check out this news";

    // Track copy status locally for the dialog
    let dialogCopyStatus = "default";

    // Determine if dark mode is active for SweetAlert custom classes
    const isDarkMode = theme === "dark";

    // Set up custom classes based on the current theme
    const customClasses = {
      container: "share-dialog-container",
      popup: `share-dialog-popup ${isDarkMode ? "bg-zinc-800" : "bg-zinc-200"}`,
      title: `${isDarkMode ? "text-zinc-200" : "text-zinc-800"}`,
      closeButton: `${
        isDarkMode
          ? "text-zinc-300 hover:text-white"
          : "text-zinc-700 hover:text-zinc-900"
      }`,
      htmlContainer: `${isDarkMode ? "text-zinc-300" : "text-zinc-700"}`,
    };

    // Create a function to render the copy button with the correct icon
    const renderCopyButton = () => {
      const copyButton = document.getElementById("copy-button");
      if (!copyButton) return;

      // Clear existing content
      copyButton.innerHTML = "";

      // Render the appropriate SVG based on status
      let iconSvg;
      if (dialogCopyStatus === "copied") {
        iconSvg = document.createElement("div");
        ReactDOM.render(
          <CopyLinkYellow style={{ height: "25px", width: "25px" }} />,
          iconSvg
        );
      } else if (theme === "dark") {
        iconSvg = document.createElement("div");
        ReactDOM.render(
          <CopyLinkWhite style={{ height: "25px", width: "25px" }} />,
          iconSvg
        );
      } else {
        iconSvg = document.createElement("div");
        ReactDOM.render(
          <CopyLinkGray style={{ height: "25px", width: "25px" }} />,
          iconSvg
        );
      }

      copyButton.appendChild(iconSvg);
    };

    Swal.fire({
      title: "Share this article",
      html: `
        <div class="flex flex-col gap-4 my-6">
          <div class="flex items-center justify-between p-3 bg-zinc-100 dark:bg-zinc-700 rounded-lg">
            <input id="share-link" class="bg-transparent flex-1 outline-none text-sm truncate mr-2 dark:text-white" value="${newsUrl}" readonly />
            <button id="copy-button" class="bg-zinc-200 hover:bg-zinc-300 dark:bg-zinc-600 dark:hover:bg-zinc-500 p-2 rounded-full text-zinc-700 dark:text-zinc-200">
              
            </button>
          </div>

          <!-- Add the copy confirmation message here but initially hidden -->
          <div id="copy-confirmation" class="text-green-500 text-sm text-center mt-1" style="opacity: 0; transition: opacity 0.3s ease-in-out;">
            Link copied to clipboard.
          </div>
          
          <div class="grid grid-cols-5 gap-3 mt-2">
            <button id="facebook-share" class="flex flex-col items-center justify-center p-3 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg>
              <span class="text-xs mt-1">Facebook</span>
            </button>
            
            <button id="twitter-share" class="flex flex-col items-center justify-center p-3 bg-sky-500 hover:bg-sky-600 text-white rounded-lg transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"/></svg>
              <span class="text-xs mt-1">Twitter</span>
            </button>
            
            <button id="instagram-share" class="flex flex-col items-center justify-center p-3 bg-gradient-to-br from-purple-500 via-pink-500 to-orange-500 text-white rounded-lg transition-colors hover:from-purple-600 hover:via-pink-600 hover:to-orange-600">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line></svg>
              <span class="text-xs mt-1">Instagram</span>
            </button>
            
            <button id="whatsapp-share" class="flex flex-col items-center justify-center p-3 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17.498 14.382c-.301-.15-1.767-.867-2.04-.966-.273-.101-.473-.15-.673.15-.2.3-.767.967-.94 1.164-.173.198-.347.223-.647.075-.3-.15-1.269-.468-2.419-1.491-.893-.8-1.498-1.786-1.674-2.086-.173-.3-.018-.462.13-.61.134-.133.301-.347.452-.52.149-.174.198-.298.297-.497.1-.198.05-.371-.025-.52s-.573-1.391-.786-1.904c-.212-.512-.43-.441-.573-.454L6.92 7.68c-.198 0-.421.074-.644.298-.223.224-.854.933-.854 2.275 0 1.342.98 2.636 1.119 2.834.137.197 1.92 3.083 4.75 4.277 2.827 1.194 2.83.795 3.338.787.51-.008 1.644-.674 1.878-1.325.233-.651.233-1.211.173-1.329-.061-.119-.24-.186-.54-.336z"/><path d="M20.52 17.5v3.5a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 2.64 3H6a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l.27-.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 20.52 17.5"/></svg>
              <span class="text-xs mt-1">WhatsApp</span>
            </button>
            
            <button id="telegram-share" class="flex flex-col items-center justify-center p-3 bg-blue-400 hover:bg-blue-500 text-white rounded-lg transition-colors">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m22 2-7 20-4-9-9-4Z"/><path d="M22 2 11 13"/></svg>
              <span class="text-xs mt-1">Telegram</span>
            </button>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      customClass: customClasses,
      backdrop: `${isDarkMode ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.4)"}`,
      didOpen: () => {
        // Apply additional styling to the SweetAlert container
        const swalContainer = document.querySelector(".swal2-container");
        const swalPopup = document.querySelector(".swal2-popup");

        if (swalContainer && swalPopup) {
          // Apply background colors based on theme
          swalPopup.style.backgroundColor = isDarkMode ? "#27272a" : "#e5e7eb"; // zinc-800 or zinc-200
          swalPopup.style.color = isDarkMode ? "#e4e4e7" : "#3f3f46"; // zinc-200 or zinc-700
        }

        // Render the initial copy button icon
        renderCopyButton();

        // Copy link button
        document.getElementById("copy-button").addEventListener("click", () => {
          const linkInput = document.getElementById("share-link");
          linkInput.select();
          navigator.clipboard.writeText(linkInput.value);

          // Update both the dialog copy status and the component state
          dialogCopyStatus = "copied";

          // Re-render the copy button with the "copied" icon
          renderCopyButton();

          // Show the confirmation message
          const copyMessage = document.getElementById("copy-confirmation");
          if (copyMessage) {
            copyMessage.style.opacity = 1;

            // Hide the message after 2 seconds
            setTimeout(() => {
              copyMessage.style.opacity = 0;
            }, 2000);
          }

          // Reset after 2 seconds
          setTimeout(() => {
            dialogCopyStatus = "default";
            // Only re-render if the dialog is still open
            if (document.getElementById("copy-button")) {
              renderCopyButton();
            }
          }, 2000);
        });

        // Instagram Story sharing
        document
          .getElementById("instagram-share")
          .addEventListener("click", () => {
            Swal.close();
            showInstagramInstructions(news, parewaLogo);
          });

        // Facebook share
        document
          .getElementById("facebook-share")
          .addEventListener("click", () => {
            const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              newsUrl
            )}&quote=${encodeURIComponent(newsTitle)}`;
            window.open(shareUrl, "_blank", "width=600,height=400");
            Swal.close();
          });

        // Twitter share
        document
          .getElementById("twitter-share")
          .addEventListener("click", () => {
            const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              newsTitle
            )}&url=${encodeURIComponent(newsUrl)}`;
            window.open(shareUrl, "_blank", "width=600,height=400");
            Swal.close();
          });

        // WhatsApp share
        document
          .getElementById("whatsapp-share")
          .addEventListener("click", () => {
            const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
              newsTitle + " " + newsUrl
            )}`;
            window.open(shareUrl, "_blank");
            Swal.close();
          });

        // Telegram share
        document
          .getElementById("telegram-share")
          .addEventListener("click", () => {
            const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
              newsUrl
            )}&text=${encodeURIComponent(newsTitle)}`;
            window.open(shareUrl, "_blank");
            Swal.close();
          });
      },
    });
  };

  // Use the liked field from the news object
  const isLiked = likedStatus === "LIKE";
  // const isDisliked = likedStatus === "DISLIKE";
  return (
    <div className="flex px-3 justify-between items-center w-full">
      {/* Like and SShare Button */}
      <div className="flex gap-2 w-fit">
        <button
          className="p-1 cursor-pointer"
          title={isLiked ? "Unlike" : "Like"}
          onClick={() => {
            const action = isLiked ? "UNLIKE" : "LIKE";
            handleLike(action);
            handlePopUpLike(true);
            setButtonName(action === "LIKE" ? "Like" : "Unlike");
          }}
        >
          {isLiked ? (
            <HeartRed
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          ) : (
            <HeartWhite
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          )}
        </button>
        {/* Share Button */}
        <button
          className="p-1 cursor-pointer"
          title="Share"
          onClick={handleShare}
        >
          {theme === "dark" || parentComponent === "NewsWithImage" ? (
            <ShareIconWhite
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          ) : (
            <ShareIconGray
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          )}
        </button>
      </div>

      {/* Save Button */}
      <div className="flex w-fit dark:text-zinc-300">
        {isSaved ? (
          <button
            className="p-1 cursor-pointer"
            title="Remove from saved"
            onClick={() => {
              deleteSavedNews(
                news._id,
                userId,
                token,
                setIsSaved,
                dispatch,
                setShowLoginAlert
              );
              setButtonName("Delete");
            }}
          >
            <SaveYellow
              style={{
                height: "25px",
                width: "25px",
              }}
            />
          </button>
        ) : (
          <button
            className="p-1 cursor-pointer"
            title="Save article"
            onClick={() => {
              saveNews(
                news._id,
                userId,
                token,
                setIsSaved,
                dispatch,
                setShowLoginAlert
              );
              setButtonName("Save");
            }}
          >
            <Bookmark
              size={25}
              className={`${
                parentComponent === "NewsWithImage" ? "text-zinc-300 " : ""
              }`}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default CardButtons;
