import React, { useEffect, useState } from "react";
import { hitApi } from "../../services/hitApi";

const CategoryFilter = ({ categories, handleCategoryChange }) => {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    // Fetch categories from the backend
    const fetchCategories = async () => {
      try {
        const response = await hitApi.get("/news/getCategories"); // Adjust the endpoint as needed

        setCategoryList(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <ul className="space-y-1 border-t border-zinc-200 dark:border-black p-4">
      {categoryList.map((category) => (
        <li key={category}>
          <label
            htmlFor={`Filter${category.replace(/\s+/g, "")}`}
            className="inline-flex items-center gap-2"
          >
            <input
              type="checkbox"
              id={`Filter${category.replace(/\s+/g, "")}`}
              className="size-5 rounded border-zinc-300"
              checked={categories.includes(category)}
              onChange={() => handleCategoryChange(category)}
            />
            <span className="text-sm font-medium text-zinc-700 dark:text-white capitalize">
              {category.replace(/_/g, " ")}
            </span>
          </label>
        </li>
      ))}
    </ul>
  );
};

export default CategoryFilter;
