import { Filter } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "../../assets/output.css";
import useToken from "../../hooks/useToken.js";
import useUser from "../../hooks/useUser.js";
import sourceImages from "../../utils/sourceImages.js";
import LoadingSkeleton from "../LoadingSkeleton.jsx";
import { useGetNewsPaginationQuery } from "../services/api/newsServices.js";
import CategorySelection from "./CategorySelection/CategorySelection.jsx";
import HeroSectionV2 from "./HeroSection/Version2/HeroSectionV2.jsx";
import LoginModal from "./Modals/LoginModal.jsx";
import SuccessfulAlert from "./Modals/SuccessfulAlert.jsx";
import NewsWithImage from "./NewsCard/NewsWithImage.js";
import NewsWithoutImage from "./NewsCard/NewsWithoutImage.js";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const glitchBanner = `${bucketUrl}/backend/assets/advertisement/glitch/GLITCH_Banner.MP4`;
const parewaSoon = `${bucketUrl}/backend/assets/advertisement/parewa/Parewa_Soon.mp4`;

const HomePAGE = () => {
  const token = useToken();
  const [selectedSourceName, setSelectedSourceName] = useState("All");
  const [sortOption, setSortOption] = useState("default");
  const [activeButton, setActiveButton] = useState("All");
  const [finalNewsData, setFinalNewsData] = useState([]);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [buttonName, setButtonName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const showModal = useSelector((state) => state.auth.showModal);

  const userHook = useUser();

  const { user } = useMemo(
    () => (token ? { user: userHook?.user } : { user: null }),
    [userHook.user, token]
  );

  const userId = user?._id;

  let query = {
    page: currentPage,
    sortOption,
    userId, // Include userId in the query
  };

  if (selectedSourceName !== "All") {
    query.source_name = selectedSourceName;
  }

  let { isError, isSuccess, isLoading, data, error } =
    useGetNewsPaginationQuery(query);

  // ---- NOTE ---- data.newsResults is the array of news!!
  let news = data?.newsResults || [];
  let totalPage = data?.totalPages || 0;

  // Separate useEffect for resetting state
  useEffect(() => {
    setFinalNewsData([]);
    setCurrentPage(1);
  }, [selectedSourceName, sortOption]);

  useEffect(() => {
    if (isSuccess) {
      setFinalNewsData((prevNews) => {
        // If current page is 1 return the news that is fetched.
        if (currentPage === 1) {
          return news;
        }
        // Filter out duplicates
        const newNews = news.filter(
          (newArticle) =>
            !prevNews.some((prevArticle) => prevArticle._id === newArticle._id)
        );
        return [...prevNews, ...newNews];
      });
      setTotalPages(totalPage);
    }

    if (isError) {
      console.log("ERROR Occured! :((( ", error?.error);
    }
  }, [isSuccess, isError, error, news, sortOption, selectedSourceName]);

  const handleSorting = (newSortOption) => {
    setSortOption(newSortOption);
  };

  const toggleSourceName = (sourceName) => {
    setSelectedSourceName(sourceName);
    setActiveButton(sourceName);

    // Scroll to the heading section
    const headingElement = document.getElementById("news-source-heading");
    if (headingElement) {
      // Adjust scroll position to account for fixed headers or overlapping elements
      const offset = -120; // Adjust this value based on your layout (e.g., height of the fixed header)
      const headingPosition =
        headingElement.getBoundingClientRect().top + window.scrollY + offset;

      window.scrollTo({
        top: headingPosition,
        behavior: "smooth",
      });
    }

    // Center the button smoothly
    const buttonElement = document.getElementById(`button-${sourceName}`);
    if (buttonElement) {
      const scrollContainer = buttonElement.closest(".overflow-x-scroll");
      if (scrollContainer) {
        const buttonLeft = buttonElement.offsetLeft;
        const containerWidth = scrollContainer.clientWidth;
        const buttonWidth = buttonElement.clientWidth;
        const scrollLeft = buttonLeft - containerWidth / 2 + buttonWidth / 2;

        // Use scrollTo with smooth behavior
        scrollContainer.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    }
  };

  const buttons = [
    { filter: "All", name: "All News", id: "source-all-news" },
    {
      filter: "Kathmandu Post",
      name: "Kathmandu Post",
      id: "source-kathmandu-post",
    },
    {
      filter: "Annapurna Post",
      name: "Annapurna Post",
      id: "source-annapurna-post",
    },
    { filter: "Nagarik News", name: "Nagarik News", id: "source-nagarik-news" },
    { filter: "Gorkhapatra", name: "Gorkhapatra", id: "source-gorkhapatra" },
    {
      filter: "Makalu Khabar Nepali",
      name: "Makalu Khabar Nepali",
      id: "source-makalu-khabar-nepali",
    },
    {
      filter: "Makalu Khabar English",
      name: "Makalu Khabar English",
      id: "source-makalu-khabar-english",
    },
    {
      filter: "Setopati Nepali",
      name: "Setopati Nepali",
      id: "source-setopati-nepali",
    },
    {
      filter: "Setopati English",
      name: "Setopati English",
      id: "source-setopati-english",
    },
    {
      filter: "Himalaya Times",
      name: "Himalaya Times",
      id: "source-himalaya-times",
    },
    {
      filter: "Karobar Daily",
      name: "Karobar Daily",
      id: "source-karobar-daily",
    },
    { filter: "Image Khabar", name: "Image Khabar", id: "source-image-khabar" },
    { filter: "Ratopati", name: "Ratopati", id: "source-ratopati" },
    {
      filter: "Online Khabar English",
      name: "Online Khabar English",
      id: "source-online-khabar-english",
    },
    {
      filter: "Online Khabar Nepali",
      name: "Online Khabar Nepali",
      id: "source-online-khabar-nepali",
    },
    {
      filter: "Rajdhani Daily",
      name: "Rajdhani Daily",
      id: "source-rajdhani-daily",
    },
    { filter: "News 24", name: "News 24", id: "source-news-24" },
    { filter: "Techmandu", name: "Techmandu", id: "source-techmandu" },
    {
      filter: "News of Nepal",
      name: "News of Nepal",
      id: "source-news-of-nepal",
    },
    { filter: "Nepal News", name: "Nepal News", id: "source-nepal-news" },
    {
      filter: "The Rising Nepal",
      name: "The Rising Nepal",
      id: "source-the-rising-nepal",
    },
    { filter: "Himal Khabar", name: "Himal Khabar", id: "source-himal-khabar" },
    { filter: "Drishti News", name: "Drishti News", id: "source-drishti-news" },
    {
      filter: "Reporters Nepal",
      name: "Reporters Nepal",
      id: "source-reporters-nepal",
    },
    { filter: "Khabarhub", name: "Khabarhub", id: "source-khabarhub" },
    { filter: "Ajako Artha", name: "Ajako Artha", id: "source-ajako-artha" },
    { filter: "BIZMANDU", name: "Bizmandu", id: "source-bizmandu" },
    { filter: "Clickmandu", name: "Clickmandu", id: "source-clickmandu" },
    { filter: "Baahrakhari", name: "Baahrakhari", id: "source-baahrakhari" },
    { filter: "Nepal Press", name: "Nepal Press", id: "source-nepal-press" },
    { filter: "Kendrabindu", name: "Kendrabindu", id: "source-kendrabindu" },
  ];

  buttons.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ zIndex: 9999 }} // Ensure the toast is above other elements
      />

      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <div className="flex bg-white dark:bg-zinc-900 min-h-screen relative">
          {/* BODY */}
          <div className="justify-center items-center flex flex-col w-full">
            {/* Hero Section */}
            <div className="flex-col flex relative justify-center items-center w-full mt-[90px]">
              {/* Banner */}
              <div className="w-full flex my-1 justify-center">
                <div
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/glitch.np/",
                      "_blank"
                    );
                  }}
                  className="w-[1280px] h-fit bg-zinc-100 dark:bg-zinc-800 rounded-sm cursor-pointer"
                >
                  <video
                    src={glitchBanner}
                    autoPlay
                    loop
                    muted
                    // controls
                    className="max-w-full h-full object-contain"
                  />
                </div>
              </div>
              {/* Banner */}

              {/* HERO SECTION with 2 ads side by side */}
              <div className="h-fit flex gap-1 items-center max-w-screen-2xl justify-center">
                {/* Latest News */}
                <div className="h-full w-full">
                  <HeroSectionV2 toggleSourceName={toggleSourceName} />
                </div>
              </div>
              {/* HERO SECTION with 2 ads side by side */}
            </div>

            {/* NEWS SECTION - LATEST ARTICLES */}
            <div className="flex-col mt-8 justify-center items-center w-full">
              <div className="flex-col justify-center flex items-center w-full">
                {/* HEADING and BUTTONS */}
                <div className="flex flex-col px-2 w-full h-full xxs:mt-0 lg:mt-10">
                  {/* Heading */}
                  <div id="news-source-heading">
                    <h2 className="hidden sm:block p-1 xxs:text-lg xxs:uppercase lg:capitalize lg:text-2xl font-Comfortaa text-center my-2 text-black dark:text-zinc-500 xl:-mt-8">
                      Select a{" "}
                      <span className="text-parewacreamcanprimary-200">
                        news source
                      </span>{" "}
                      to explore Nepal's latest news
                    </h2>
                    <h2 className="block sm:hidden p-2 bg-white border-2 dark:bg-zinc-900 dark:shadow-xl dark:border-2 dark:border-zinc-800 dark:text-white tracking-wide rounded-lg font-Comfortaa shadow-md mb-2">
                      News Feed
                    </h2>
                  </div>
                  {/* Heading */}

                  {/* Buttons */}
                  <div className="flex-col items-center mb-5 p-3 shadow-md bg-white dark:bg-zinc-900 w-full">
                    {/* SORTING Option */}
                    <div className="flex items-center font-Comfortaa bg-zinc-200 dark:bg-zinc-800 rounded-lg w-fit">
                      <div className="p-2 border-r border-zinc-300 dark:border-black flex justify-center">
                        <Filter className="text-zinc-500 dark:text-parewacreamcanprimary-300 xxs:h-2/3 xxs:w-2/3 lg:h-full lg:w-full" />
                      </div>
                      <select
                        id="sort"
                        onChange={(e) => handleSorting(e.target.value)}
                        value={sortOption}
                        className="outline-none text-black xxs:text-sm lg:text-base dark:text-parewacreamcanprimary-200 p-1 hover:text-gray-500 transition duration-200 bg-zinc-200 dark:bg-zinc-800"
                      >
                        <option disabled>Select Filter</option>
                        <option value="default">By Default</option>
                        <option value="title">Title</option>
                        <option value="dateOldest">Date (Oldest First)</option>
                      </select>
                    </div>
                    {/* SORTING Option */}

                    {/* Source Name Buttons */}
                    <div className="w-full overflow-x-scroll gap-4 no-scrollbar flex py-2 dark:text-zinc-400 justify-start items-center">
                      {buttons.map((button) => {
                        return (
                          <button
                            key={button.filter}
                            id={`button-${button.filter}`} // Assign ID to the button
                            onClick={() => {
                              toggleSourceName(button.filter);
                            }}
                            className={`dark:hover:bg-zinc-800 hover:bg-zinc-300 px-2 p-1 h-fit text-center w-fit flex xxs:text-sm lg:text-base font-Comfortaa tracking-widee rounded-lg transition-all duration-300 ease-in-out ${
                              activeButton === button.filter
                                ? "bg-parewacreamcanprimary-100 text-black rounded-lg px-2"
                                : ""
                            }`}
                          >
                            <span
                              id={button.id}
                              className="w-60 flex xxs:justify-start lg:justify-center items-center"
                            >
                              {sourceImages[button.name] && (
                                <img
                                  src={sourceImages[button.name]}
                                  alt={button.name}
                                  className="w-6 h-6 mr-2 rounded-full object-cover"
                                />
                              )}
                              {button.name}
                            </span>
                          </button>
                        );
                      })}
                    </div>
                    {/* Source Name Buttons */}
                  </div>
                  {/* Buttons */}
                </div>
                {/* BUTTONS */}

                {/* News Articles */}
                <div className="w-full flex xxs:flex-col sm:flex-row relative">
                  {/* NEWS CARDS */}
                  <div className="w-full h-full flex flex-col">
                    <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 xxs:grid-cols-1 gap-4 font-sans h-full w-full px-2">
                      {isLoading ? (
                        <LoadingSkeleton />
                      ) : (
                        finalNewsData.map((item, index) => (
                          <div
                            key={item._id}
                            className="relative h-full w-full"
                          >
                            {item.source_name === "Himalaya Times" ||
                            item.source_name === "Ratopati" ||
                            item.source_name === "Ajako Artha" ||
                            !item.image_source ? (
                              <NewsWithoutImage
                                key={item._id}
                                news={item}
                                setShowLoginAlert={setShowLoginAlert}
                                setButtonName={setButtonName}
                                buttonName={buttonName}
                                toggleSourceName={toggleSourceName}
                              />
                            ) : (
                              <NewsWithImage
                                key={item._id}
                                news={item}
                                setShowLoginAlert={setShowLoginAlert}
                                setButtonName={setButtonName}
                                buttonName={buttonName}
                                toggleSourceName={toggleSourceName}
                              />
                            )}
                          </div>
                        ))
                      )}
                    </div>
                    {/* Show More Button */}
                    {currentPage < totalPages && (
                      <div className="w-full mt-4 p-2 mb-2 rounded-lg font-Comfortaa">
                        <div className="flex justify-center">
                          <button
                            onClick={() => setCurrentPage((prev) => prev + 1)}
                            className="px-4 py-2 bg-zinc-100 dark:bg-zinc-900 bg-gradient-to-t from-zinc-100 dark:from-zinc-800 to-zinc-50 dark:to-zinc-900 shadow-lg text-black dark:text-white rounded-full hover:bg-opacity-50 hover:text-opacity-25 dark:hover:bg-opacity-50 dark:hover:text-opacity-25"
                          >
                            Show More
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Banner */}
                    <div className="w-full flex justify-center my-2">
                      <div
                        onClick={() => {
                          window.open(
                            "https://www.instagram.com/parewaainc/",
                            "_blank"
                          );
                        }}
                        className="w-[1280px] h-fit bg-zinc-100 dark:bg-zinc-800 cursor-pointer"
                      >
                        <video
                          src={parewaSoon}
                          autoPlay
                          loop
                          muted
                          // controls
                          className="max-w-full h-full object-contain xxs:rounded-none xl:rounded-lg"
                        />
                      </div>
                    </div>
                    {/* Banner */}
                  </div>
                </div>
              </div>
            </div>
            {/* NEWS SECTION - LATEST ARTICLES */}

            <div className="max-w-screen-2xl border dark:border-zinc-600 my-10">
              <CategorySelection />
            </div>

            {/* <div className="max-w-screen-2xl">
              <h1 className="font-Comfortaa dark:text-white text-xl">
                मुख्य समाचार
              </h1>
              <BreakingNews toggleSourceName={toggleSourceName} />
            </div> */}
          </div>
          {/* BODY */}

          {showModal && (
            <div className="fixed top-auto left-auto inset-2 z-50 max-h-fit max-w-sm">
              <SuccessfulAlert buttonName={buttonName} />
            </div>
          )}

          {showLoginAlert && (
            <div className="fixed inset-2 z-50 max-h-full  rounded-lg max-w-screen bg-zinc-100 dark:bg-zinc-900 dark:bg-opacity-90 bg-opacity-80 font-Comfortaa flex items-center justify-center">
              <div className="h-fit flex flex-col items-center bg-parewaBackground bg-no-repeat bg-left">
                <div className="flex p-6 w-full justify-center bg-zinc-100 dark:bg-black dark:bg-opacity-80 text-black dark:text-white shadow-md mb-2 rounded-lg">
                  You are about to{" "}
                  <span className="text-parewacreamcanprimary-200 mr-1 ml-1">
                    {buttonName}
                  </span>{" "}
                  a news article
                </div>
                <LoginModal setShowLoginAlert={setShowLoginAlert} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePAGE;
