import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../../config/config.js";

// const customBaseQuery = async ({ url, method, body }) => {
//   const token =
//     localStorage.getItem("token") || sessionStorage.getItem("token");

//   if (token) {
//     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
//   } else {
//     delete axios.defaults.headers.common["Authorization"];
//   }

//   try {
//     const result = await axios({
//       method,
//       url: baseURL + url,
//       data: body,
//     });
//     return { data: result.data };
//   } catch (axiosError) {
//     // Extracting serializable error information
//     const errorInfo = {
//       message: axiosError.message,
//       name: axiosError.name,
//       code: axiosError.code,
//       config: axiosError.config,
//       response: {
//         data: axiosError.response?.data,
//         status: axiosError.response?.status,
//         statusText: axiosError.response?.statusText,
//       },
//     };
//     return { error: errorInfo };
//   }
// };

export const usersApi = createApi({
  reducerPath: "getUser",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: (headers) => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["getUsers", "getUserById"],

  endpoints: (builder) => ({
    getUsers: builder.query({
      // .query is only for GET requests.
      // Because this is rendered with the page.

      // So remember, TAGS are provided in a QUERY.
      query: () => {
        return {
          url: "/users",
          method: "GET",
        };
      },

      // STEP 2: Adding/Providing the TAG to the query.
      providesTags: ["getUsers"],

      // This is for the cache invalidation to work as it looks for the tag name.
    }),

    getUserById: builder.query({
      // .query is only for GET requests.
      // Because this is rendered with the page.

      // So remember, TAGS are provided in a QUERY.
      query: ({ id, token }) => {
        if (!id || !token) {
          return null; // If id or token is not provided, don't make the API call.
        }
        return {
          url: `/users/${id}`,
          method: "GET",
          headers: {
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${token}`,
          },
        };
      },

      providesTags: ["getUserById"],
    }),

    createUser: builder.mutation({
      // .mutation is for POST, PUT, DELETE requests.
      // Because this is not rendered with the page. Only rendered when a button is clicked!!

      // So remember, TAGS are invalidated in a MUTATION.
      query: (body) => {
        return {
          url: "/users",
          method: "POST",
          body: body,
        };
      },

      // invalidatesTags: ["readProduct", "readProductById"], == to invalidate multiple tags, only when required!
      invalidatesTags: ["getUsers"],
    }),

    forgotPassword: builder.mutation({
      query: (email) => {
        return {
          url: "/users/forgot-password",
          method: "POST",
          body: { email },
        };
      },
      invalidatesTags: ["getUsers"],
    }),

    resetPassword: builder.mutation({
      query: ({ token, password }) => {
        return {
          url: `/users/reset-password`,
          method: "PATCH",
          body: { password },
          headers: {
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["getUsers"],
    }),

    // REMEMBER, getUsers, getUserById, etc. all these are functions that can be called from the component
    // and parameters can be sent!!

    deleteUser: builder.mutation({
      // .mutation is for POST, PUT, DELETE requests.
      // Because this is not rendered with the page. Only rendered when a button is clicked!!

      // So remember, TAGS are invalidated in a MUTATION.
      query: (id) => {
        return {
          url: `/users/${id}`,
          method: "DELETE",
        };
      },

      // STEP 3: Removing/Invalidating the TAG from the query.
      invalidatesTags: ["getUsers"],
      // THIS Helps in hitting the API again and getting the updated data by using the provided TAG.
      //This helps for cache invalidation.
    }),

    // INVALIDATE detail page while UPDATING a product. --------------------

    updateUser: builder.mutation({
      // .mutation is for POST, PUT, DELETE requests.
      // Because this is not rendered with the page. Only rendered when a button is clicked!!

      // So remember, TAGS are invalidated in a MUTATION.
      query: ({ body, token }) => {
        return {
          url: `/users/update-profile/${body._id}`,
          method: "PATCH",
          body: body,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },

      // STEP 3: Removing/Invalidating the TAG from the query.
      invalidatesTags: ["getUsers", "getUserById"],
      // THIS Helps in hitting the API again and getting the updated data by using the provided TAG.
      //This helps for cache invalidation.
    }),

    updateUserPassword: builder.mutation({
      // .mutation is for POST, PUT, DELETE requests.
      // Because this is not rendered with the page. Only rendered when a button is clicked!!

      // So remember, TAGS are invalidated in a MUTATION.
      query: (body) => {
        return {
          url: `/users/update-password/${body.id}`,
          method: "PUT",
          body: body,
        };
      },

      // STEP 3: Removing/Invalidating the TAG from the query.
      invalidatesTags: ["getUserById"],
      //This helps for cache invalidation.
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserByIdQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useCreateUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = usersApi;
