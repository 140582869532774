import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../../config/config.js";

const prepareHeaders = (headers, { getState }) => {
  const token = getState().auth.token; // Adjust the path to your token in the state
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const newsApi = createApi({
  reducerPath: "getNews",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders,
  }),

  tagTypes: ["getNews", "getNewsById"],

  endpoints: (builder) => ({
    getNews: builder.query({
      // .query is only for GET requests.
      // Because this is rendered with the page.

      // So remember, TAGS are provided in a QUERY.
      query: () => {
        return {
          url: "/news",
          method: "GET",
        };
      },

      // STEP 2: Adding/Providing the TAG to the query.
      providesTags: ["getNews"],

      // This is for the cache invalidation to work as it looks for the tag name.
    }),

    // getNewsBySourceName: builder.query({
    //   // .query is only for GET requests.
    //   // Because this is rendered with the page.

    //   // So remember, TAGS are provided in a QUERY.
    //   query: ({ source_name }) => {
    //     return {
    //       url: `/news-by-source?source_name=${source_name}`,
    //       method: "GET",
    //     };
    //   },

    //   // STEP 2: Adding/Providing the TAG to the query.
    //   providesTags: ["getNewsBySourceName"],

    //   // This is for the cache invalidation to work as it looks for the tag name.
    // }),

    getNewsPagination: builder.query({
      query: ({ page, source_name, sortOption, userId }) => {
        let url = "/news/pagination";
        const params = new URLSearchParams();
        if (page) params.append("page", page);
        if (source_name) params.append("source_name", source_name);
        // In order to allow multiple source_names selection:
        // if (source_names) {
        //   const sourceNamesArray = source_names.split(",");
        //   sourceNamesArray.forEach((source_name) =>
        //     params.append("source_name", source_name)
        //   );
        // }
        if (sortOption) params.append("sortOption", sortOption);
        if (params.toString()) url += `?${params.toString()}`;

        const headers = {};
        if (userId) {
          headers["User-Id"] = userId; // Include userId in the headers if provided
        }

        return {
          url,
          method: "GET",
          headers,
        };
      },

      providesTags: ["getNews"],
    }),

    getNewsWithSimilarSources: builder.query({
      query: () => ({
        url: "/news/similar-sources",
        method: "GET",
      }),
      providesTags: ["getNewsWithSimilarSources"],
    }),

    getSavedNews: builder.query({
      // .query is only for GET requests.
      // Because this is rendered with the page.

      // So remember, TAGS are provided in a QUERY.
      query: () => {
        return {
          url: "/users/news/save-activity",
          method: "GET",
        };
      },

      // STEP 2: Adding/Providing the TAG to the query.
      providesTags: ["getSavedNews"],

      // This is for the cache invalidation to work as it looks for the tag name.
    }),

    getNewsById: builder.query({
      // .query is only for GET requests.
      // Because this is rendered with the page.

      // So remember, TAGS are provided in a QUERY.
      query: (id) => {
        return {
          url: `/news/${id}`,
          method: "GET",
        };
      },

      providesTags: ["getNewsById"],
    }),

    // getNewsByType: builder.query({
    //   query: ({
    //     type,
    //     source,
    //     sortOption,
    //     timeOption,
    //     page = 1,
    //     itemsPerPage = 16,
    //   }) => {
    //     // Construct the initial query object
    //     let query = {
    //       type,
    //       source,
    //       sortOption,
    //       timeOption,
    //       page,
    //       itemsPerPage,
    //     };

    //     // Filter out properties with no value
    //     const filteredQuery = Object.fromEntries(
    //       Object.entries(query).filter(
    //         ([_, value]) =>
    //           value !== undefined &&
    //           value !== null &&
    //           value !== "" &&
    //           value.length !== 0
    //       )
    //     );

    //     return {
    //       url: `/news/categories?${new URLSearchParams(
    //         filteredQuery
    //       ).toString()}`,
    //       method: "GET",
    //     };
    //   },
    //   providesTags: ["getNewsByType"],
    // }),

    getNewsByType: builder.query({
      query: ({
        type,
        source,
        sortOption,
        timeOption,
        page = 1,
        itemsPerPage = 16,
      }) => {
        let url = "/news/categories";
        const params = new URLSearchParams();

        if (type) params.append("type", type);
        if (source && source !== "All") {
          params.append("source", source);
        }
        if (sortOption && sortOption !== "By Default") {
          params.append("sortOption", sortOption);
        }
        if (timeOption) params.append("timeOption", timeOption);
        if (page) params.append("page", page);
        if (itemsPerPage) params.append("itemsPerPage", itemsPerPage);

        if (params.toString()) url += `?${params.toString()}`;

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["getNewsByType"],
    }),

    getNewsBySearch: builder.query({
      query: ({
        page = 1,
        searchTerm,
        selectedFilter,
        categories,
        dateFrom,
        dateTo,
      }) => {
        if (!searchTerm) {
          return null;
        }

        // Construct the initial query object
        let query = {
          searchQuery: searchTerm,
          page,
          selectedFilter,
          categories,
          dateFrom,
          dateTo,
        };

        // Filter out properties with no value
        const filteredQuery = Object.fromEntries(
          Object.entries(query).filter(
            ([_, value]) =>
              value !== undefined &&
              value !== null &&
              value !== "" &&
              value.length !== 0
          )
        );

        const request = {
          url: `/news/search?${new URLSearchParams(filteredQuery).toString()}`,
          method: "GET",
        };

        return request;
      },
      providesTags: ["getNewsBySearch"],
    }),
    // REMEMBER, .....News is a function that can be called from the component
    // and parameters can be sent!!

    deleteNews: builder.mutation({
      // .mutation is for POST, PUT, DELETE requests.
      // Because this is not rendered with the page. Only rendered when a button is clicked!!

      // So remember, TAGS are invalidated in a MUTATION.
      query: (id) => {
        return {
          url: `/news/${id}`,
          method: "DELETE",
        };
      },

      // STEP 3: Removing/Invalidating the TAG from the query.
      invalidatesTags: ["getNews", "getSavedNews", "getNewsByType"], // THIS Helps in hitting the API again and getting the updated data by using the provided TAG.
      //This helps for cache invalidation.
    }),

    // INVALIDATE detail page while UPDATING a product. --------------------

    getNewsWithImages: builder.query({
      query: () => {
        return {
          url: "/news/with-images",
          method: "GET",
        };
      },
      providesTags: ["getNewsWithImages"],
    }),

    createVerification: builder.mutation({
      query: (body) => {
        return {
          url: "/news/verify",
          method: "POST",
          body: body,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    // New Query to Fetch Recent Searches
    getRecentSearches: builder.query({
      query: () => ({
        url: "/news/recent-searches",
        method: "GET",
      }),
      providesTags: ["getRecentSearches"],
    }),

    // New Mutation to Update Recent Searches
    updateRecentSearches: builder.mutation({
      query: (searchTerm) => ({
        url: "/news/recent-searches",
        method: "POST",
        body: { searchTerm },
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["getRecentSearches"],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsPaginationQuery,
  useGetSavedNewsQuery,
  useGetNewsByIdQuery,
  useGetNewsWithImagesQuery,
  useDeleteNewsMutation,
  useUpdateNewsMutation,
  useGetNewsByTypeQuery,
  useGetNewsBySearchQuery,
  useCreateVerificationMutation,
  useGetRecentSearchesQuery,
  useUpdateRecentSearchesMutation,
  useGetNewsWithSimilarSourcesQuery,
  useGetNewsBySourceNameQuery,
} = newsApi;
