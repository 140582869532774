import { useEffect, useRef, useState } from "react";
import useUser from "../../../hooks/useUser";
import NewLoginModal from "../../HomePage/Modals/NewLoginModal";
import { useSelector } from "react-redux";
import { useQueryLLMMutation } from "../../services/api/gradioServices";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaLogoLight = `${bucketUrl}/backend/assets/e-parewa_dark.png`;
const parewaLogoDark = `${bucketUrl}/backend/assets/e-parewa_light.png`;

const HeroVerifyLLM = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [query, setQuery] = useState("");
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hello! How can I help you today?",
    },
    {
      sender: "user",
      text: "I need to verify some information.",
    },
    {
      sender: "bot",
      text: "Of course! What would you like to verify?",
    },
  ]);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const { user } = useUser();
  const theme = useSelector((state) => state.auth.mode);
  const [queryLLM, { isLoading }] = useQueryLLMMutation();

  const messagesEndRef = useRef(null);

  // const handleOpenLoginModal = () => {
  //   setShowLoginModal(true);
  // };

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (!query.trim() || isLoading) return;

    // Add user message to chat
    const userMessage = { sender: "user", text: query };
    setMessages((prev) => [...prev, userMessage]);

    // Show typing indicator
    setIsTyping(true);

    try {
      // Send query to backend
      const response = await queryLLM({
        query_text: query,
        top_k: 10,
      }).unwrap();
      setIsTyping(false);

      if (
        response.success &&
        response.data.articles &&
        response.data.articles.length > 0
      ) {
        // Add bot response with articles
        const botResponse = {
          sender: "bot",
          text: `I found ${response.data.articles.length} articles related to your query.`,
          articles: response.data.articles,
        };
        setMessages((prev) => [...prev, botResponse]);
      } else {
        // No articles found
        const botResponse = {
          sender: "bot",
          text: "I couldn't find any articles matching your query. Please try a different search term.",
        };
        setMessages((prev) => [...prev, botResponse]);
      }
    } catch (error) {
      console.error("Error querying LLM:", error);
      setIsTyping(false);

      // Add error message
      const errorMessage = {
        sender: "bot",
        text: "Sorry, I encountered an error while processing your request. Please try again later.",
      };
      setMessages((prev) => [...prev, errorMessage]);
    }

    // Clear input
    setQuery("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
  };

  const handleCloseArticle = () => {
    setSelectedArticle(null);
  };

  return (
    <div className="w-full max-w-screen bg-zinc-100 dark:bg-zinc-900 h-full">
      <div className="container mx-auto max-w-4xl mb-10 xxs:mt-[60px] sm:mt-[40px] h-[calc(100vh-120px)] flex flex-col bg-zinc-100 dark:bg-zinc-900 overflow-hidden rounded-lg dark:rounded-none">
        {/* Chat interface header */}
        <div className="w-full flex flex-col justify-center items-center gap-2 py-4 my-2">
          <img
            src={theme === "dark" ? parewaLogoDark : parewaLogoLight}
            alt="Parewa News Logo"
            className="h-8"
          />
          <hr className="w-40 mt-2 border-2 border-zinc-300 dark:border-zinc-800 rounded-full" />
        </div>
        {/* Article detail modal */}
        {selectedArticle && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white dark:bg-zinc-900 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h2 className="text-xl font-bold dark:text-white">
                    {selectedArticle.title}
                  </h2>
                  <button
                    onClick={handleCloseArticle}
                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {selectedArticle.image_source && (
                  <img
                    src={selectedArticle.image_source}
                    alt={selectedArticle.title}
                    className="w-full h-48 object-cover mb-4 rounded"
                  />
                )}
                <div className="flex justify-between items-center mb-3">
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {selectedArticle.source_name}
                  </span>
                  <span className="text-sm text-gray-600 dark:text-gray-400">
                    {formatDate(selectedArticle.pub_date)}
                  </span>
                </div>
                <p className="text-gray-800 dark:text-gray-300 mb-4">
                  {selectedArticle.description}
                </p>
                {selectedArticle.category &&
                  selectedArticle.category.length > 0 && (
                    <div className="mb-4">
                      <h3 className="text-sm font-semibold mb-1 dark:text-white">
                        Categories:
                      </h3>
                      <div className="flex flex-wrap gap-2">
                        {selectedArticle.category.map((cat, i) => (
                          <span
                            key={i}
                            className="bg-gray-200 dark:bg-zinc-700 px-2 py-1 text-xs rounded dark:text-white"
                          >
                            {cat}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                <a
                  href={selectedArticle.source_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                >
                  Read Full Article
                </a>
              </div>
            </div>
          </div>
        )}
        {/* Chat messages area - Using flex-grow to take available space */}
        <div
          className="flex-grow overflow-y-auto px-4 no-scrollbar py-2"
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="flex flex-col space-y-4">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`flex ${
                  msg.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`${
                    msg.sender === "user"
                      ? "bg-zinc-200 dark:bg-zinc-700 rounded-lg"
                      : "shadow-md rounded-lg dark:shadow-xl border dark:border-zinc-900"
                  } py-2 px-4 rounded-2xl max-w-[80%] dark:text-white text-black`}
                >
                  <p className="text-sm p-1">{msg.text}</p>
                  {/* Articles list */}
                  {msg.articles && msg.articles.length > 0 && (
                    <div className="mt-3">
                      <div className="dark:bg-zinc-800 dark:text-white text-black bg-zinc-200 px-3 py-2 rounded-full text-xs font-bold mb-2 w-fit">
                        Found {msg.articles.length} articles :
                      </div>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {msg.articles.map((article, index) => (
                          <button
                            key={index}
                            onClick={() => handleArticleClick(article)}
                            className="bg-zinc-200 hover:bg-opacity-25 dark:bg-zinc-800 dark:hover:bg-opacity-60 text-xs rounded-lg px-3 py-1.5 flex items-center transition-colors"
                          >
                            <span className="grid grid-cols-1">
                              {index + 1}.{" "}
                              {article.title.length > 50
                                ? article.title.substring(0, 50) + "..."
                                : article.title}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
            {/* Typing indicator */}
            {isTyping && (
              <div className="flex justify-start">
                <div className="bg-gray-200 dark:bg-zinc-700 dark:text-white p-3 rounded-2xl">
                  <div className="flex space-x-1">
                    <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
                    <div
                      className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                      style={{ animationDelay: "0.2s" }}
                    ></div>
                    <div
                      className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"
                      style={{ animationDelay: "0.4s" }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            {/* <div ref={messagesEndRef} /> */}
          </div>
        </div>
        {/* Input area - Fixed at bottom */}
        <div className="p-4">
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Ask anything related to news"
              className="flex-grow p-3 bg-white dark:bg-zinc-700 rounded-l-lg border-0 focus:ring-0 focus:outline-none dark:text-white text-sm md:text-base"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              disabled={!user || isLoading}
            />
            <button
              className="p-3 bg-parewacreamcanprimary-200 hover:bg-opacity-80 text-white hover:text-black rounded-r-lg transition-colors disabled:bg-gray-400 disabled:text-white"
              onClick={handleSendMessage}
              disabled={!user || isLoading || !query.trim()}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        {/* Half overlay with gradient - show only when not loading and no user */}
        {!user && (
          <div className="absolute inset-0 pointer-events-none">
            {/* Top half - transparent */}
            <div className="h-2/5 w-full"></div>
            {/* Bottom half - gradient with login message */}
            <div className="h-3/5 w-full bg-gradient-to-t from-zinc-600 via-zinc-400 to-transparent dark:from-zinc-900 dark:via-zinc-800 dark:to-transparent flex flex-col items-center justify-end pb-8 pointer-events-auto">
              <div className="text-center text-2xl px-4 text-white">
                {/* <h3 className="text-black dark:text-white font-bold text-xl mb-2">
                  Please Log In
                </h3>
                <p className="text-zinc-800 dark:text-zinc-400 mb-6">
                  You need to be logged in to use this chat interface.
                </p>
                <button
                  className="bg-parewacreamcanprimary-200 text-white py-3 px-8 rounded-lg hover:bg-opacity-60 transition dark:hover:bg-opacity-60"
                  onClick={handleOpenLoginModal}
                >
                  Log In
                </button> */}
                COMING SOONER THAN YOUR MORNING TEA 😉
              </div>
            </div>
          </div>
        )}
        {/* Login Modal */}
        <NewLoginModal
          isOpen={showLoginModal}
          onClose={handleCloseLoginModal}
        />
      </div>
    </div>
  );
};

export default HeroVerifyLLM;
