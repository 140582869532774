import { motion } from "framer-motion";
import { CheckCheck, Home } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/output.css";
import SuccessfulModal from "../../LoginPage/Modals/SuccessfulModal.jsx";
import MoreInfo from "../../LoginPage/MoreInfo.jsx";
import PersonalInfo from "../../LoginPage/PersonalInfo.jsx";
import SignUp from "../../LoginPage/SignUp.jsx";
import { hitApi } from "../../services/hitApi.js";
import ProgressBar from "../ProgressBar/ProgressBar.jsx";
import TermsModal from "./TermsModal/TermsModal.jsx";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaPhoto = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;
const backgroundImageTry = `${bucketUrl}/backend/assets/wallpapers/simpleBg.png`;

const RegisterForm = () => {
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status
  const [isTypingPassword, setIsTypingPassword] = useState(false); // Track if user is typing password
  const [password, setPassword] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false); // State to control the terms modal
  const navigate = useNavigate();
  const titles = ["Sign Up", "Personal Info", "More Info"];
  const totalSteps = titles.length; // Adjust based on the number of steps in your registration process
  const [error, setError] = useState("");

  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    occupation: "",
    dateOfBirth: "",
    gender: "",
    city: "",
    country: "",
    phoneNumber: "",
    acceptedTermsConditions: false,
  });

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setData((prevData) => ({ ...prevData, password: value }));
    setPassword(value);
    setIsTypingPassword(value.length > 0);
  };

  const validateFields = () => {
    const {
      username,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      occupation,
      dateOfBirth,
      gender,
    } = data;

    // Check if all the required fields are filled
    if (
      !username ||
      !email ||
      !password ||
      !confirmPassword ||
      !firstName ||
      !lastName ||
      !occupation ||
      !dateOfBirth ||
      !gender
    ) {
      setError("Please fill in all the required fields");
      return false;
    }

    return true;
  };

  const trimData = (data) => {
    const trimmedData = { ...data };
    Object.keys(trimmedData).forEach((key) => {
      if (
        key !== "password" &&
        key !== "confirmpassword" &&
        key !== "acceptedTermsConditions"
      ) {
        trimmedData[key] = trimmedData[key].trim();
      }
    });
    return trimmedData;
  };

  const RegisterUser = async (updatedData) => {
    const trimmedData = trimData(updatedData);

    const {
      username,
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      occupation,
      dateOfBirth,
      gender,
      city,
      country,
      phoneNumber,
      acceptedTermsConditions,
    } = trimmedData;

    setIsLoading(true); // Show the loading component
    setIsSubmitting(true); // Set submitting status

    try {
      // eslint-disable-next-line
      let output = await hitApi({
        method: "POST",
        url: "/users/register",
        data: {
          username,
          email,
          password,
          confirmPassword,
          firstName,
          lastName,
          occupation,
          dateOfBirth,
          gender,
          location: {
            // Nest city and country inside a location object
            city,
            country,
          },
          phoneNumber,
          acceptedTermsConditions, // Include accepted terms in the user details
        },
      });
      setShowModal(true);
    } catch (error) {
      console.log("Error registering user:", error.message);
    } finally {
      setIsLoading(false); // Hide the loading component
      setIsSubmitting(false); // Reset submitting status
    }
  };

  const PageDisplay = () => {
    let Component;
    if (page === 0) {
      Component = (
        <SignUp
          data={data}
          setData={setData}
          setError={setError}
          handlePasswordChange={handlePasswordChange}
          isTypingPassword={isTypingPassword}
          password={password}
        />
      );
    } else if (page === 1) {
      Component = (
        <PersonalInfo data={data} setData={setData} setError={setError} />
      );
    } else {
      Component = <MoreInfo data={data} setData={setData} />;
    }

    return (
      <div>
        {error && (
          <p className="text-sm text-red-500 text-center w-full tracking-wider">
            {"*" + error + "*"}
          </p>
        )}
        {Component}
      </div>
    );
  };

  const handleAcceptTerms = () => {
    setData((prevData) => {
      const updatedData = { ...prevData, acceptedTermsConditions: true };
      setShowTermsModal(false); // Close the terms modal after accepting terms
      RegisterUser(updatedData); // Call the RegisterUser function with updated data after accepting terms
      return updatedData;
    });
  };

  const handleSubmit = () => {
    if (validateFields()) {
      setShowTermsModal(true); // Show the terms modal only if fields are valid
    }
  };

  return (
    <div
      className="bg-cover bg-no-repeat bg-center w-full h-screen font-Comfortaa xxs:flex-col lg:flex-row flex items-center gap-2"
      style={{
        backgroundImage: `url(${backgroundImageTry})`,
      }}
    >
      <div className="w-full xxs:h-64 md:h-full flex items-center">
        <div className="max-w-2xl flex mx-auto p-6 bg-zinc-100 rounded-lg shadow-lg bg-opacity-50 h-1/4 xxs:h-full items-center ">
          <div className="flex-col">
            <h2 className="xxs:text-center md:text-left xxs:text-lg md:text-3xl font-extrabold text-gray-900">
              Welcome to Parewa Community
            </h2>
            <p className="mt-2 xxs:p-2 md:p-0 text-left xxs:text-xs md:text-md text-gray-600">
              Please fill out the form to create your account. It is quick and
              easy!
            </p>
          </div>
          <div className="xxs:hidden md:flex xxs:w-0 md:w-1/2 items-center justify-center">
            <img
              src={parewaPhoto}
              alt="logo"
              className="xxs:h-24 xxs:w-24 lg:w-28 lg:h-28"
            ></img>
          </div>
        </div>
      </div>

      <div className="xxs:h-fit md:h-full w-full flex-col items-center flex xxs:justify-start md:justify-center">
        <div className="flex xxs:w-full sm:max-w-xl border-2 shadow-lg border-zinc-100 justify-center m-4 p-4 rounded-full">
          <ProgressBar currentStep={page + 1} totalSteps={totalSteps} />
        </div>

        <div className="w-full">
          <div className="sm:mx-auto p-2">
            <motion.h1
              className="subpixel-antialiased xxs:text-lg md:text-3xl text-center hover:uppercase tracking-wide"
              initial={{ y: -1000 }}
              animate={{ y: 0 }}
              transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            >
              {titles[page]}
            </motion.h1>
          </div>

          <motion.div
            className="mt-2 sm:mx-auto h-fit shadow-lg xxs:w-full sm:max-w-xl"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
          >
            <div className="bg-zinc-100 p-5 shadow rounded-lg sm:px-10">
              <div>{PageDisplay()}</div>
              <div className="flex flex-row gap-3 mt-4">
                <button
                  onClick={() => {
                    if (page === 0) {
                      navigate("/login"); // Redirect to login page
                    } else {
                      setPage((currentPage) => currentPage - 1); // Go to previous page
                    }
                  }}
                  className="flex cursor-pointer w-full justify-center rounded-lg border bg-zinc-900 py-2 px-4 text-sm font-medium text-white hover:bg-opacity-25 hover:text-black focus:ring-2 focus:ring-black"
                >
                  {page === 0 ? "Back to Login" : "Prev"}
                </button>
                <button
                  onClick={(e) => {
                    if (page === titles.length - 1) {
                      handleSubmit(); // Validate fields and show terms modal if valid
                    } else {
                      setPage((currentPage) => currentPage + 1);
                    }
                  }}
                  className="flex cursor-pointer disabled:cursor-not-allowed w-full justify-center rounded-lg border bg-zinc-900 py-2 px-4 text-sm font-medium text-white hover:bg-opacity-25 hover:text-black focus:ring-2 focus:ring-black"
                  disabled={isSubmitting} // Disable button when submitting
                >
                  {isSubmitting
                    ? "Submitting..."
                    : page === titles.length - 1
                    ? "Submit"
                    : "Next"}
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <TermsModal
        open={showTermsModal}
        onClose={() => setShowTermsModal(false)}
        onAccept={handleAcceptTerms}
      />

      <SuccessfulModal open={showModal} onClose={() => setShowModal(false)}>
        <div
          className={`bg-white rounded-lg shadow-md p-6 transition-all ${
            showModal ? "scale-100 opacity-100" : "scale-125 opacity-0"
          }`}
        >
          <h2 className="w-full text-center uppercase tracking-widest font-bold text-2xl mt-2">
            Registration successful!
          </h2>
          {isLoading ? (
            <div>working our brains...</div>
          ) : (
            <div className="flex flex-col xxs:p-2 sm:p-6">
              <div className="flex-col flex gap-2 justify-center w-full">
                <div className="flex justify-center">
                  <CheckCheck className="text-green-500 h-60 w-1/2" size={48} />
                </div>
                <h1 className="text-center font-bold flex items-center w-full gap-2 justify-center text-2xl tracking-widest uppercase">
                  Welcome to Parewa
                  <img
                    src={parewaPhoto}
                    alt="logo"
                    className="xxs:hidden sm:flex sm:h-20 sm:w-20"
                  ></img>
                </h1>
              </div>

              <div className="flex-col">
                <div className="mt-4 w-full p-1 text-sm">
                  <p className="text-zinc-900 tracking-wide text-xs relative">
                    <span className="text-red-500 absolute top-0 -left-2 text-sm">
                      *
                    </span>
                    Before You Login :
                  </p>
                  <div className="flex flex-wrap px-4 p-2 bg-zinc-100 text-black mt-2 rounded-lg">
                    {/* <span className="text-red-500 absolute top-0 left-1">
                      *
                    </span> */}
                    Please check your
                    <span className="text-parewacreamcanprimary-200 px-1">
                      email
                    </span>
                    for a confirmation link to complete your registration
                  </div>
                </div>
                <div className="flex flex-col mt-4 xxs:items-start justify-center md:items-center">
                  <div className="w-full flex xxs:justify-start md:justify-center">
                    <span className="flex m-2 text-sm text-zinc-500 tracking-wider">
                      You can continue browsing for now
                    </span>
                    <button onClick={() => setShowModal(false)}>
                      <div className="flex items-center px-2 border rounded-lg shadow-md hover:bg-zinc-100">
                        <Home size={18} />
                        <button
                          onClick={() => {
                            navigate("/home");
                          }}
                          className="p-1 text-sm"
                        >
                          Home
                        </button>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </SuccessfulModal>
    </div>
  );
};

export default RegisterForm;
