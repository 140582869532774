import { Field } from "formik";
import React from "react";

const FormikSelect = ({
  name,
  label,
  placeholder,
  onChange,
  required,
  options,
  ...props
}) => {
  // Making the FORM DYNAMIC
  // ...props allows to enter multiple other properties than the given ONES above.
  // ...props means the remaning properties

  return (
    <div>
      <Field name={name}>
        {({ field, form, meta }) => {
          // "!!" changes to BOOLEAN
          // IF onChange is being sent then use it otherwise use field.onChange
          // onChange = !!onChange ? onChange : field.onChange; // HERE is ABOVE! ----------

          return (
            <div>
              <label htmlFor={name}>{label}</label>
              <div className="flex relative">
                <div className="flex-col w-full">
                  <select
                    {...field}
                    {...props}
                    id={name}
                    value={meta.value}
                    onChange={onChange ? onChange : field.onChange}
                  >
                    {options.map((item, i) => {
                      return (
                        // Since this is returning an array, a UNIQUE key is required in the PARENT element
                        <option
                          key={i}
                          value={item.value}
                          disabled={item.disabled}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {required ? (
                  <span className="text-red-500 top-0">*</span>
                ) : null}
              </div>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikSelect;
