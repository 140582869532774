import { Field } from "formik";
import React from "react";

const FormikInput = ({
  name,
  label,
  placeholder,
  type,
  onChange,
  required,
  ...props
}) => {
  // Making the FORM DYNAMIC
  // ...props allows to enter multiple other properties than the given ONES above.
  // ...props means the remaning properties

  return (
    <div>
      <Field name={name}>
        {({ field, form, meta }) => {
          // "!!" changes to BOOLEAN
          // IF onChange is being sent then use it otherwise use field.onChange
          onChange = !!onChange ? onChange : field.onChange; // HERE is ABOVE! ----------

          return (
            <div>
              <label htmlFor={name}>{label}</label>
              <div className="flex relative">
                <div className="flex-col w-full">
                  <input
                    {...field} // This has to be there EVERYTIME!!!
                    {...props} // This allows other extra properties to be added to the field
                    type={type}
                    id={name}
                    placeholder={placeholder}
                    value={meta.value}
                    onChange={onChange}
                    // onChange={onChange ? onChange : field.onChange} --- THIS is the same as ABOVE ---
                  />

                  {meta.touched && meta.error ? (
                    <div className="text-red-600 text-xs mt-1 ml-1">
                      {meta.error + "*"}
                    </div>
                  ) : null}
                </div>
                {required ? (
                  <span className="text-red-500 top-0">*</span>
                ) : null}
              </div>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikInput;
