import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ totalPages, setCurrentPage }) => {
  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    setCurrentPage(selectedPage);
  };

  return (
    <div className="dark:text-white text-zinc-400 my-2">
      <ReactPaginate
        breakLabel={
          <span className="text-black dark:text-parewacreamcanprimary-100">
            ...
          </span>
        }
        nextLabel={
          <span className="w-10 h-10 flex items-center justify-center text-black dark:text-parewacreamcanprimary-100 bg-zinc-200 dark:bg-zinc-800 hover:bg-opacity-25 dark:hover:bg-opacity-25 rounded-lg text-sm cursor-pointer">
            <ChevronRight size={28} strokeWidth={3} />
          </span>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        pageCount={totalPages}
        previousLabel={
          <span className="w-10 h-10 flex items-center justify-center text-black dark:text-parewacreamcanprimary-100 bg-zinc-200 dark:bg-zinc-800 hover:bg-opacity-25 dark:hover:bg-opacity-25 rounded-lg text-sm cursor-pointer">
            <ChevronLeft size={28} strokeWidth={3} />
          </span>
        }
        renderOnZeroPageCount={null}
        containerClassName="flex xxs:justify-start sm:justify-center items-center gap-10 overflow-scroll no-scrollbar"
        pageClassName="block rounded-lg m-2 p-2 cursor-pointer text-sm text-black dark:text-parewacreamcanprimary-100"
        activeClassName="bg-parewacreamcanprimary-100 bg-opacity-60 dark:bg-zinc-600"
      />
    </div>
  );
};

export default Pagination;
