import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../../config/config.js";

const preparedHeaders = (headers, { getState }) => {
  const token = getState().auth.token; // Adjust the path to your token in the state
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const gradioApi = createApi({
  reducerPath: "gradioApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders: preparedHeaders,
  }),

  tagTypes: ["getGradio"],

  endpoints: (builder) => ({
    queryLLM: builder.mutation({
      query: (data) => ({
        url: "users/query-llm",
        method: "POST",
        body: data,
      }),

      invalidatesTags: ["getGradio"],
    }),
  }),
});

export const { useQueryLLMMutation } = gradioApi;
