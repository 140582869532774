import { CheckCircle } from "@styled-icons/bootstrap/CheckCircle";
import { CheckCircleFill } from "@styled-icons/bootstrap/CheckCircleFill";
import { useEffect, useState } from "react";

const PasswordValidation = ({ password }) => {
  const [passwordValidation, setPasswordValidation] = useState({
    has8letters: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
  });

  const validatePassword = (password) => {
    const has8letters = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    setPasswordValidation({
      has8letters,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
    });
  };

  // Validate password whenever it changes
  useEffect(() => {
    validatePassword(password);
  }, [password]);

  return (
    <div className="p-2 rounded-lg">
      <p className="text-xs">Password must contain</p>
      <ul>
        <li className="flex items-center gap-2 font-sans font-medium max-h-fit w-fit p-1">
          {passwordValidation?.has8letters ? (
            <CheckCircleFill className="text-green-500" size={15} />
          ) : (
            <CheckCircle className="text-red-500" size={15} />
          )}
          <span className="text-[11px]">Minimum 8 Characters</span>
        </li>
        <li className="flex items-center gap-2 font-sans font-medium max-h-fit w-fit p-1">
          {passwordValidation?.hasUpperCase ? (
            <CheckCircleFill className="text-green-500" size={15} />
          ) : (
            <CheckCircle className="text-red-500" size={15} />
          )}
          <span className="text-[11px]">Uppercase Letter</span>
        </li>
        <li className="flex items-center gap-2 font-sans font-medium max-h-fit w-fit p-1">
          {passwordValidation?.hasLowerCase ? (
            <CheckCircleFill className="text-green-500" size={15} />
          ) : (
            <CheckCircle className="text-red-500" size={15} />
          )}
          <span className="text-[11px]">Lowercase Letter</span>
        </li>
        <li className="flex items-center gap-2 font-sans font-medium max-h-fit w-fit p-1">
          {passwordValidation?.hasNumber ? (
            <CheckCircleFill className="text-green-500" size={15} />
          ) : (
            <CheckCircle className="text-red-500" size={15} />
          )}
          <span className="text-[11px]">Number</span>
        </li>
      </ul>
    </div>
  );
};

export default PasswordValidation;
