import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../../config/config.js";

const prepareHeaders = (headers, { getState }) => {
  const token = getState().auth.token;
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
};

export const categoriesApi = createApi({
  reducerPath: "getCategories",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders,
  }),

  tagTypes: ["getCategories"],

  endpoints: (builder) => ({
    // Get all distinct categories
    getDistinctCategories: builder.query({
      query: () => ({
        url: "/news/distinct-categories",
        method: "GET",
      }),
      providesTags: ["getCategories"],
    }),

    // Get news by category with pagination, sorting, and time filter
    getNewsByCategory: builder.query({
      query: ({
        category,
        page = 1,
        itemsPerPage = 20,
        sortBy = "pub_date",
        order = "desc",
      }) => {
        let url = "/news/byCategory";
        const params = new URLSearchParams();

        if (category) params.append("category", category);
        if (page) params.append("page", page);
        if (itemsPerPage) params.append("itemsPerPage", itemsPerPage);
        if (sortBy) params.append("sortBy", sortBy);
        if (order) params.append("order", order);

        return {
          url: `${url}?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: (result, error, { category }) => [
        { type: "getCategories", id: category },
      ],
      // Add polling interval (2 minutes = 120000 milliseconds)
      pollingInterval: 120000,
      // Refetch when window focuses
      refetchOnFocus: true,
      // Refetch when reconnecting
      refetchOnReconnect: true,
    }),
  }),
});

export const { useGetDistinctCategoriesQuery, useGetNewsByCategoryQuery } =
  categoriesApi;
