const bucketUrl = process.env.REACT_APP_BUCKET_URL;

// Define stock images for different categories
const stockImages = {
  "Breaking News": `${bucketUrl}/backend/assets/stockImages/breaking_news/first_try.svg`,
  // Politics: `${bucketUrl}/backend/assets/stockImages/politics/politics.svg`,
  // Business: `${bucketUrl}/backend/assets/stockImages/business/business.svg`,
  // Entertainment: `${bucketUrl}/backend/assets/stockImages/entertainment/entertainment.svg`,
  // Sports: `${bucketUrl}/backend/assets/stockImages/sports/sports.svg`,
  // Technology: `${bucketUrl}/backend/assets/stockImages/technology/technology.svg`,
  // Health: `${bucketUrl}/backend/assets/stockImages/health/health.svg`,
  // Science: `${bucketUrl}/backend/assets/stockImages/science/science.svg`,
  // Education: `${bucketUrl}/backend/assets/stockImages/education/education.svg`,
  // Opinion: `${bucketUrl}/backend/assets/stockImages/opinion/opinion.svg`,
  // Environment: `${bucketUrl}/backend/assets/stockImages/environment/environment.svg`,
  // World: `${bucketUrl}/backend/assets/stockImages/world/world.svg`,
  // Thinking: `${bucketUrl}/backend/assets/stockImages/thinking/thinking.svg`,
  // Default fallback
  default: `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`,
};

const LazyImage = ({ src, alt, className, news }) => {
  // Get the appropriate stock image based on news type or category
  const getStockImage = () => {
    if (!news) return stockImages.default;

    const category =
      news.type || (news.category && news.category[0]) || "Thinking";

    // Capitalize first letter of each word to match our stockImages keys
    const formattedCategory = category
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return stockImages[formattedCategory] || stockImages.default;
  };

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite error loops
        e.target.src = getStockImage();
      }}
      loading="lazy"
    />
  );
};

export default LazyImage;
