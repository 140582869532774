// let htmlDateFormat = (date = new Date()) => {
//   date = new Date(date || new Date()).toLocaleDateString().split("/");

//   let year = date[2];
//   let month = date[0].length === 1 ? `0${date[0]}` : date[0];
//   let day = date[1].length === 1 ? `0${date[1]}` : date[1];

//   //Changing to yyyy-MM-dd format
//   let dateFormat = `${year}-${month}-${day}`;
//   return dateFormat;
// };

export const htmlDateFormat = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  let suffix = "th";
  if (day % 10 === 1 && day !== 11) {
    suffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    suffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    suffix = "rd";
  }

  return (
    <>
      {day}
      <sup>{suffix}</sup> {month} {year}
    </>
  );
};
