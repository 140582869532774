import { Trophy } from "@styled-icons/bootstrap/Trophy";
import { Music } from "@styled-icons/boxicons-regular/Music";
import { LearningApp } from "@styled-icons/fluentui-system-regular/LearningApp";
import { News } from "@styled-icons/fluentui-system-regular/News";
import { NotebookSubsection } from "@styled-icons/fluentui-system-regular/NotebookSubsection";
import { StyleGuide } from "@styled-icons/fluentui-system-regular/StyleGuide";
import { Video } from "@styled-icons/fluentui-system-regular/Video";
import { Fitness } from "@styled-icons/ionicons-outline/Fitness";
import { Business } from "@styled-icons/ionicons-sharp/Business";
import { GameController } from "@styled-icons/ionicons-solid/GameController";
import { Dining } from "@styled-icons/material-outlined/Dining";
import { FamilyRestroom } from "@styled-icons/material-outlined/FamilyRestroom";
import { DevicesOther } from "@styled-icons/material-rounded/DevicesOther";
import { HomeHeart } from "@styled-icons/remix-line/HomeHeart";
import { TravelCase } from "@styled-icons/zondicons/TravelCase";
import { Code2, Compass, Map, Popcorn, UsersRound } from "lucide-react";
import { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import sourceImages from "../../utils/sourceImages.js";
import Loading from "../Loading.jsx";
import { useGetNewsByTypeQuery } from "../services/api/newsServices.js";
import CardButtons from "./CardButtons/CardButtons";
import LazyImage from "./LazyImage.jsx";
import "./NewspaperFeels.css";
import { Time } from "@styled-icons/ionicons-outline/Time";
import moment from "moment";
import "moment/locale/ne";
import { HeartFill } from "@styled-icons/bootstrap/HeartFill";
import { motion } from "framer-motion";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const typeIcons = {
  "arts_&_culture": <UsersRound size={25} />,
  "business_&_entrepreneurs": <Business size={25} />,
  "celebrity_&_pop_culture": <Popcorn size={25} />,
  "diaries_&_daily_life": <NotebookSubsection size={25} />,
  family: <FamilyRestroom size={25} />,
  "fashion_&_style": <StyleGuide size={25} />,
  "film_tv_&_video": <Video size={25} />,
  "fitness_&_health": <Fitness size={25} />,
  "food_&_dining": <Dining size={25} />,
  gaming: <GameController size={25} />,
  "learning_&_educational": <LearningApp size={25} />,
  music: <Music size={25} />,
  "news_&_social_concern": <News size={25} />,
  other_hobbies: <DevicesOther size={25} />,
  relationships: <HomeHeart size={25} />,
  "science_&_technology": <Code2 size={25} />,
  sports: <Trophy size={25} />,
  "travel_&_adventure": <TravelCase size={25} />,
  uncertain: <Map size={25} />,
  "youth_&_student_life": <Compass size={25} />,
  // Add more icons as needed
};

const NewspaperFeels = ({ setButtonName, setShowLoginAlert }) => {
  const types = useSelector((state) => state.types.types);
  const [selectedType, setSelectedType] = useState(() => {
    return localStorage.getItem("selectedType") || types[0] || "";
  });
  const { data, error, isLoading } = useGetNewsByTypeQuery({
    type: selectedType,
  });
  const [likedArticleId, setLikedArticleId] = useState(null);

  const handlePopUpLike = (articleId) => {
    setLikedArticleId(articleId);
    setTimeout(() => {
      setLikedArticleId(null);
    }, 1000);
  };

  const newsAccordingToType = data?.newsResults || [];

  const handleTypeChange = (event) => {
    const newType = decodeURIComponent(event.target.value);
    setSelectedType(newType);
    localStorage.setItem("selectedType", newType);
  };

  useEffect(() => {
    if (types.length > 0 && !selectedType) {
      const initialType = types[0];
      setSelectedType(initialType);
      localStorage.setItem("selectedType", initialType);
    }
  }, [types, selectedType]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error loading news articles.</div>;
  }

  return (
    <div className="xxs:p-0 sm:p-4 w-fit flex justify-center items-center">
      <div>
        <h2 className="lg:text-lg font-bold p-2 xxs:text-xs dark:text-white font-Comfortaa flex flex-wrap items-center justify-center">
          Latest Articles from
          <select
            value={selectedType}
            onChange={handleTypeChange}
            className="m-1 p-1 border dark:border-black rounded dark:bg-zinc-800 dark:text-white capitalize"
          >
            {types.map((type) => (
              <option key={type} value={type}>
                {typeIcons[type]} {type.replace(/_/g, " ")}
              </option>
            ))}
          </select>
        </h2>

        <div className="grid-container-newspaper">
          <div className="grid-newspaper">
            {newsAccordingToType.map((article, index) => (
              <div
                key={index}
                className="border dark:border-zinc-900 dark:bg-zinc-800 h-fit w-[300px] shadow-md rounded-lg p-3 hover:shadow-lg flex flex-col justify-between relative"
                style={{
                  gridRowEnd: `span ${article.image_source ? 2 : 1}`,
                }}
              >
                {likedArticleId === article._id && (
                  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-25">
                    <motion.div
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{
                        delay: 0.2,
                        type: "spring",
                        stiffness: 60,
                        damping: 10,
                        duration: 0.8,
                        ease: "easeInOut",
                      }}
                      className="p-4 rounded-lg text-white"
                    >
                      <HeartFill size={36} />
                    </motion.div>
                  </div>
                )}
                <div className="w-full h-fit">
                  {article.source_name !== "Annapurna Post" &&
                    article.source_name !== "Kathmandu Post" &&
                    article.source_name !== "Ajako Artha" && (
                      <div className="flex md:flex-col lg:flex-row items-center pb-2">
                        <Time size={15} className="mr-1 dark:text-zinc-300" />
                        <span className="text-xs tracking-wide dark:text-white">
                          {moment(article.pub_date)
                            .locale("ne")
                            .format("MMMM Do YYYY, h:mm:ss a")}
                        </span>
                      </div>
                    )}

                  {article.image_source && (
                    <Suspense fallback={<Loading />}>
                      <LazyImage
                        src={article.image_source}
                        alt="img"
                        className="rounded-sm hover:scale-105 hover:transition-all hover:duration-300 hover:ease-in-out aspect-square w-full max-h-full object-cover object-center"
                      />
                    </Suspense>
                  )}

                  <div className={`${article.image_source ? "mt-2" : "mt-4"}`}>
                    <h3 className="font-semibold xxs:text-sm sm:text-lg w-fit dark:text-white px-1">
                      {article.title
                        ? new DOMParser()
                            .parseFromString(article.title, "text/html")
                            .body.textContent.split(" ")
                            .slice(0, 20)
                            .join(" ")
                        : ""}
                    </h3>
                  </div>
                </div>

                <div className="flex flex-wrap justify-between items-center p-1 my-6">
                  <p className="text-sm text-gray-600 dark:text-gray-300 flex gap-2 font-Comfortaa items-center font-thin">
                    <img
                      src={sourceImages[article.source_name] || parewaImg}
                      width={25}
                      height={25}
                      className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                      alt="source_img"
                    ></img>
                    {article.source_name}
                  </p>
                </div>

                <div>
                  <a
                    className="news-card-learn-more-button w-fit rounded-lg ml-1"
                    href={`/news/${article._id}`}
                  >
                    Learn More
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                  <CardButtons
                    setButtonName={setButtonName}
                    news={article}
                    handlePopUpLike={() => handlePopUpLike(article._id)}
                    setShowLoginAlert={setShowLoginAlert}
                    className="flex justify-between my-2 dark:text-gray-600"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewspaperFeels;
