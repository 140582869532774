import { useParams } from "react-router-dom";
import { useGetNewsByCategoryQuery } from "../services/api/categoryServices";
import Loading from "../Loading";
import CardButtons from "../HomePage/CardButtons/CardButtons";
import sourceImages from "../../utils/sourceImages";
import LazyImage from "../HomePage/LazyImage";
import { Suspense } from "react";
import moment from "moment";
import "moment/locale/ne";
import { Time } from "@styled-icons/ionicons-outline/Time";
import LoadingNew from "../LoadingSkeleton";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;

const NewsAccordingtoNepali = () => {
  const { category } = useParams();
  const { data, isLoading, error } = useGetNewsByCategoryQuery({
    category: decodeURIComponent(category),
    page: 1,
    itemsPerPage: 16,
  });

  if (isLoading) return <LoadingNew />;
  if (error) return <div>Error loading news</div>;

  return (
    <div className="w-full px-6 py-12 mt-16">
      <h1 className="font-Comfortaa text-2xl mb-6 dark:text-white">
        {decodeURIComponent(category)} News
      </h1>
      <div className="flex flex-wrap justify-center gap-4 max-w-screen">
        {data?.data?.news.map((article) => (
          <div
            key={article._id}
            className="border rounded-lg p-4 dark:bg-zinc-800 dark:border-zinc-700 flex flex-col gap-2 max-w-xs h-fit"
          >
            <div className="w-full h-fit">
              {article.source_name !== "Annapurna Post" &&
                article.source_name !== "Kathmandu Post" &&
                article.source_name !== "Ajako Artha" && (
                  <div className="flex md:flex-col lg:flex-row items-center pb-2">
                    <Time size={15} className="mr-1 dark:text-zinc-300" />
                    <span className="text-xs tracking-wide dark:text-white">
                      {moment(article.pub_date)
                        .locale("ne")
                        .format("MMMM Do YYYY, h:mm:ss a")}
                    </span>
                  </div>
                )}

              {article.image_source && (
                <Suspense fallback={<Loading />}>
                  <LazyImage
                    src={article.image_source}
                    alt="img"
                    className="rounded-sm hover:scale-105 hover:transition-all hover:duration-300 hover:ease-in-out aspect-square w-full max-h-full object-cover object-center"
                  />
                </Suspense>
              )}

              <div className={`${article.image_source ? "mt-2" : "mt-4"}`}>
                <h3 className="font-semibold xxs:text-sm sm:text-lg w-fit dark:text-white px-1">
                  {article.title
                    ? new DOMParser()
                        .parseFromString(article.title, "text/html")
                        .body.textContent.split(" ")
                        .slice(0, 20)
                        .join(" ")
                    : ""}
                </h3>

                <p className="text-sm text-zinc-600 dark:text-zinc-400 h-fit py-2 px-1">
                  {article.description
                    ? new DOMParser()
                        .parseFromString(article.description, "text/html")
                        .body.textContent.slice(0, 100) + "..."
                    : ""}
                </p>
              </div>
            </div>

            <div className="flex flex-wrap justify-between items-center p-1 my-6">
              <p className="text-sm text-gray-600 dark:text-gray-300 flex gap-2 font-Comfortaa items-center font-thin">
                <img
                  src={sourceImages[article.source_name] || parewaImg}
                  width={25}
                  height={25}
                  className="object-cover object-center rounded-full xxs:w-5 xxs:h-5 lg:w-6 lg:h-6"
                  alt="source_img"
                ></img>
                {article.source_name}
              </p>
            </div>

            <div>
              <a
                className="news-card-learn-more-button w-fit rounded-lg ml-1"
                href={`/news/${article._id}`}
              >
                Learn More
                <svg
                  className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
              <CardButtons
                // setButtonName={setButtonName}
                news={article}
                // handlePopUpLike={() => handlePopUpLike(article._id)}
                // setShowLoginAlert={setShowLoginAlert}
                className="flex justify-between my-2 dark:text-gray-600"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsAccordingtoNepali;
