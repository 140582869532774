import { Home, Moon, Sun } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../slice/authSlice";
import { useForgotPasswordMutation } from "../services/api/usersService";
import VerifyOtp from "./VerifyOtp";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaPhoto = `${bucketUrl}/backend/assets/parewa_logo1.PNG`;

const ResetPassword = () => {
  const theme = useSelector((state) => state.auth.mode); // get theme from Redux store
  const dispatch = useDispatch();
  const [forgotPassword, { isLoading, isError, isSuccess }] =
    useForgotPasswordMutation();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowError(true); // Show the error message again
    forgotPassword(email);
  };

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const toggleTheme = () => {
    dispatch(
      setMode(theme === "light" ? "dark" : "light") // dispatch action to change theme in Redux store
    );
  };

  return (
    <div className="xxs:bg-white xxs:p-2 md:p-0 md:bg-zinc-200 dark:bg-zinc-900 h-screen w-screen font-Comfortaa">
      <div className="h-full w-full justify-center flex items-center">
        <div className="w-fit px-6 bg-white dark:bg-zinc-700 shadow-xl rounded-lg py-24 mx-auto lg:py-32">
          <div className="lg:flex p-4">
            <div className="lg:w-1/2">
              <img className="w-22 h-20" src={parewaPhoto} alt="logo"></img>

              <h1 className="mt-4 text-zinc-600 dark:text-zinc-300 md:text-lg">
                Parewa
              </h1>

              <p className="mt-4 flex-col flex text-zinc-800 dark:text-white">
                <span className="text-sm font-bold tracking-tight capitalize">
                  Please enter your email address.
                </span>
                <span className="text-xs mt-2">
                  We will send you an{" "}
                  <span className="text-parewacreamcanprimary-200 cursor-none">
                    email
                  </span>{" "}
                  with instructions on how to reset your password.
                </span>
              </p>
            </div>

            <div className="mt-8 lg:w-1/2 lg:mt-0">
              <form className="w-full lg:max-w-xl" onSubmit={handleSubmit}>
                <div className="relative flex items-center">
                  <span className="absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 mx-3 text-zinc-300 dark:text-zinc-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                      />
                    </svg>
                  </span>

                  <input
                    type="email"
                    className="block w-full py-3 text-zinc-700 bg-white border rounded-lg px-11 dark:bg-zinc-600 dark:text-zinc-300 dark:border-zinc-600 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                </div>

                <div className="mt-8 flex-col flex w-full items-center">
                  {isLoading ? (
                    <div className="dark:text-zinc-400">Loading...</div>
                  ) : isSuccess ? (
                    <>
                      <button
                        disabled
                        className="px-6 py-3 text-sm font-bold tracking-tight text-white hover:text-black capitalize transition-colors duration-500 transform bg-zinc-900 rounded-lg w-full hover:bg-zinc-500 focus:outline-none focus:ring focus:ring-zinc-300 focus:ring-opacity-50"
                      >
                        Check the email for an OTP (One Time Password)
                      </button>
                      <div className="border-2 p-2 text-xs bg-zinc-200 rounded-lg dark:border-zinc-800 dark:bg-zinc-700 dark:text-zinc-400 mt-4">
                        Enter the OTP below to reset your password.
                      </div>

                      <VerifyOtp email={email} />
                    </>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="px-6 py-3 text-sm font-bold tracking-tight text-white hover:text-black capitalize transition-colors duration-500 transform bg-zinc-900 rounded-lg w-full hover:bg-zinc-500 focus:outline-none focus:ring focus:ring-zinc-300 focus:ring-opacity-50"
                      >
                        Submit
                      </button>
                      <a
                        href="/login"
                        className="w-full flex justify-center mt-1 px-1 py-3 text-xs tracking-tight text-parewacreamcanprimary-200 capitalize hover:underline"
                      >
                        Cancel
                      </a>
                    </>
                  )}
                  {showError && isError && (
                    <div className="relative border-2 dark:border-zinc-900 rounded-lg p-2 text-xs dark:text-zinc-400 bg-zinc-200 dark:bg-zinc-800 mt-2">
                      <button
                        onClick={() => setShowError(false)}
                        className="absolute bottom-0 right-0 p-1 text-[8px] hover:text-zinc-300 dark:hover:text-black"
                      >
                        X
                      </button>
                      The entered email does not correspond to a registered user
                      in{" "}
                      <span className="text-parewacreamcanprimary-200">
                        Parewa
                      </span>
                      . <br /> Please check your email and try again.
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>

          {/* DARK/LIGHT MODE Button */}
          <div className="items-center justify-center flex w-10 h-10 mx-auto">
            <button
              className="hover:scale-105 transition-all duration-300"
              onClick={toggleTheme}
              title="Toggle Mode"
            >
              {theme === "light" ? (
                <Moon className="text-zinc-200" size={25} />
              ) : (
                <Sun className="dark:text-zinc-600" size={25} />
              )}
            </button>
          </div>
          {/* DARK/LIGHT MODE Button */}

          {/* HOME BUTTON */}
          <div className="flex justify-center mt-8">
            <button
              title="Home"
              className="text-sm text-zinc-200 dark:text-zinc-600 hover:scale-105 transition-all duration-300"
              onClick={() => window.location.replace("/home")}
            >
              <Home size={25} />
            </button>
          </div>
          {/* HOME BUTTON */}

          <div className="mt-8 md:mt-24 p-4 sm:flex sm:items-center">
            <h2 className="text-parewacreamcanprimary-200 sm:w-1/2 cursor-none">
              Social networks
            </h2>

            <div className="flex items-center mt-4 sm:mt-0 justify-evenly sm:w-1/2">
              {/* <a
                className="mx-1.5 dark:hover:text-parewacreamcanprimary-200 text-zinc-400 transition-colors duration-300 transform hover:text-parewacreamcanprimary-200"
                href="/"
              >
                <svg
                  className="w-10 h-10 fill-current"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.6668 6.67334C18.0002 7.00001 17.3468 7.13268 16.6668 7.33334C15.9195 6.49001 14.8115 6.44334 13.7468 6.84201C12.6822 7.24068 11.9848 8.21534 12.0002 9.33334V10C9.83683 10.0553 7.91016 9.07001 6.66683 7.33334C6.66683 7.33334 3.87883 12.2887 9.3335 14.6667C8.0855 15.498 6.84083 16.0587 5.3335 16C7.53883 17.202 9.94216 17.6153 12.0228 17.0113C14.4095 16.318 16.3708 14.5293 17.1235 11.85C17.348 11.0351 17.4595 10.1932 17.4548 9.34801C17.4535 9.18201 18.4615 7.50001 18.6668 6.67268V6.67334Z" />
                </svg>
              </a> */}

              <a
                className="mx-1.5 dark:hover:text-parewacreamcanprimary-200 text-zinc-400 transition-colors duration-300 transform hover:text-parewacreamcanprimary-200"
                href="https://np.linkedin.com/company/parewa-inc"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="w-8 h-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2 8.80005C16.4731 8.80005 17.694 9.30576 18.5941 10.2059C19.4943 11.1061 20 12.327 20 13.6V19.2H16.8V13.6C16.8 13.1757 16.6315 12.7687 16.3314 12.4687C16.0313 12.1686 15.6244 12 15.2 12C14.7757 12 14.3687 12.1686 14.0687 12.4687C13.7686 12.7687 13.6 13.1757 13.6 13.6V19.2H10.4V13.6C10.4 12.327 10.9057 11.1061 11.8059 10.2059C12.7061 9.30576 13.927 8.80005 15.2 8.80005Z"
                    fill="currentColor"
                  />
                  <path
                    d="M7.2 9.6001H4V19.2001H7.2V9.6001Z"
                    fill="currentColor"
                  />
                  <path
                    d="M5.6 7.2C6.48366 7.2 7.2 6.48366 7.2 5.6C7.2 4.71634 6.48366 4 5.6 4C4.71634 4 4 4.71634 4 5.6C4 6.48366 4.71634 7.2 5.6 7.2Z"
                    fill="currentColor"
                  />
                </svg>
              </a>

              <a
                className="mx-1.5 dark:hover:text-parewacreamcanprimary-200 text-zinc-400 transition-colors duration-300 transform hover:text-parewacreamcanprimary-200"
                href="https://www.facebook.com/profile.php?id=61555923404677"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="w-8 h-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 10.2222V13.7778H9.66667V20H13.2222V13.7778H15.8889L16.7778 10.2222H13.2222V8.44444C13.2222 8.2087 13.3159 7.9826 13.4826 7.81591C13.6493 7.64921 13.8754 7.55556 14.1111 7.55556H16.7778V4H14.1111C12.9324 4 11.8019 4.46825 10.9684 5.30175C10.1349 6.13524 9.66667 7.2657 9.66667 8.44444V10.2222H7Z"
                    fill="currentColor"
                  />
                </svg>
              </a>

              <a
                className="mx-1.5 dark:hover:text-parewacreamcanprimary-200 text-zinc-400 transition-colors duration-300 transform hover:text-parewacreamcanprimary-200"
                href="https://www.instagram.com/parewaainc"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  className="w-8 h-8"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.9294 7.72275C9.65868 7.72275 7.82715 9.55428 7.82715 11.825C7.82715 14.0956 9.65868 15.9271 11.9294 15.9271C14.2 15.9271 16.0316 14.0956 16.0316 11.825C16.0316 9.55428 14.2 7.72275 11.9294 7.72275ZM11.9294 14.4919C10.462 14.4919 9.26239 13.2959 9.26239 11.825C9.26239 10.354 10.4584 9.15799 11.9294 9.15799C13.4003 9.15799 14.5963 10.354 14.5963 11.825C14.5963 13.2959 13.3967 14.4919 11.9294 14.4919ZM17.1562 7.55495C17.1562 8.08692 16.7277 8.51178 16.1994 8.51178C15.6674 8.51178 15.2425 8.08335 15.2425 7.55495C15.2425 7.02656 15.671 6.59813 16.1994 6.59813C16.7277 6.59813 17.1562 7.02656 17.1562 7.55495ZM19.8731 8.52606C19.8124 7.24434 19.5197 6.10901 18.5807 5.17361C17.6453 4.23821 16.51 3.94545 15.2282 3.88118C13.9073 3.80621 9.94787 3.80621 8.62689 3.88118C7.34874 3.94188 6.21341 4.23464 5.27444 5.17004C4.33547 6.10544 4.04628 7.24077 3.98201 8.52249C3.90704 9.84347 3.90704 13.8029 3.98201 15.1238C4.04271 16.4056 4.33547 17.5409 5.27444 18.4763C6.21341 19.4117 7.34517 19.7045 8.62689 19.7687C9.94787 19.8437 13.9073 19.8437 15.2282 19.7687C16.51 19.708 17.6453 19.4153 18.5807 18.4763C19.5161 17.5409 19.8089 16.4056 19.8731 15.1238C19.9481 13.8029 19.9481 9.84704 19.8731 8.52606ZM18.1665 16.5412C17.8881 17.241 17.349 17.7801 16.6456 18.0621C15.5924 18.4799 13.0932 18.3835 11.9294 18.3835C10.7655 18.3835 8.26272 18.4763 7.21307 18.0621C6.51331 17.7837 5.9742 17.2446 5.69215 16.5412C5.27444 15.488 5.37083 12.9888 5.37083 11.825C5.37083 10.6611 5.27801 8.15832 5.69215 7.10867C5.97063 6.40891 6.50974 5.8698 7.21307 5.58775C8.26629 5.17004 10.7655 5.26643 11.9294 5.26643C13.0932 5.26643 15.596 5.17361 16.6456 5.58775C17.3454 5.86623 17.8845 6.40534 18.1665 7.10867C18.5843 8.16189 18.4879 10.6611 18.4879 11.825C18.4879 12.9888 18.5843 15.4916 18.1665 16.5412Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
