import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import FormikInput from "../FormikInput";
import "../../../assets/output.css";
import { PiEye, PiEyeSlash } from "react-icons/pi";

const LoginForm = ({
  buttonName = "",
  onSubmit = () => {},
  isLoading,
  isSuccess,
  setRememberMe,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordEye = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    identifier: "",
    password: "",
  };

  const validationSchema = yup.object({
    identifier: yup.string().required("Email or Username is required"),
    password: yup.string().required("Password is required"),
  });

  return (
    <div className="flex flex-col justify-center font-Comfortaa">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form className="max-w-[400px] w-full mx-auto bg-white dark:bg-zinc-600 dark:bg-opacity-50 shadow-xl p-4 rounded-lg">
              <div className="flex flex-col p-4">
                <label className="antialiased font-medium tracking-widest text-[12px] dark:text-zinc-300">
                  Email/Username
                </label>
                <FormikInput
                  id="identifier_input"
                  name="identifier"
                  type="text"
                  placeholder="Email or Username"
                  className="border-2 rounded-b-lg p-2 w-full placeholder:text-[12px] dark:text-zinc-300"
                ></FormikInput>

                <div className="relative">
                  <label className="antialiased font-medium tracking-widest text-[12px] mt-2 dark:text-zinc-300">
                    Password
                  </label>
                  <FormikInput
                    id="password_input"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="border-2 rounded-b-lg p-2 w-full placeholder:text-[12px] dark:text-zinc-300"
                  ></FormikInput>
                  <div className="text-2xl absolute top-8 right-2 dark:text-zinc-500 text-zinc-300">
                    {showPassword ? (
                      <PiEyeSlash onClick={togglePasswordEye} />
                    ) : (
                      <PiEye onClick={togglePasswordEye} />
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-center mt-2">
                  <p className="flex flex-row gap-1 items-center p-1 antialiased text-xs text-black dark:text-white">
                    <input
                      className=""
                      type="checkbox"
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    Remember Me
                  </p>

                  <div className="flex ml-auto text-black dark:text-white hover:text-blue-500 dark:hover:text-blue-500">
                    <a
                      href="/reset-password-email"
                      className="flex items-center group text-xs font-bold"
                    >
                      <span className="dark:text-parewacreamcanprimary-200 dark:hover:text-blue-500 mr-1">
                        Forgot
                      </span>
                      your password?
                    </a>
                  </div>
                </div>

                <button
                  className="button border rounded-b-lg w-full my-5 py-2 antialiased bg-zinc-700 text-left p-2 tracking-tighter font-bold text-parewacreamcanprimary-200 hover:bg-zinc-900 hover:text-white transition-all duration-500 ease-in-out"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center h-fit w-fit p-1">
                      <div
                        className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-parewacreamcanprimary-200"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    </div>
                  ) : isSuccess ? (
                    <button disabled>Login Successful!</button> // Show a success message after login
                  ) : (
                    <div id="login_button">{buttonName}</div>
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginForm;
