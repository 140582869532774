import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { setLogin } from "./slice/authSlice";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const token = localStorage.getItem("token") || sessionStorage.getItem("token");
const user = JSON.parse(
  localStorage.getItem("user") || sessionStorage.getItem("user")
);

if (token && user) {
  store.dispatch(setLogin({ user, token }));
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
