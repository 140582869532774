import { hitApi } from "../components/services/hitApi";
import { setLogin } from "../slice/authSlice";
import {
  setLikedNews,
  setTotalDislikes,
  setTotalLikes,
} from "../slice/likedNewsSlice";

export const handleLogin = async ({
  values,
  onSubmitProps,
  dispatch,
  setIsLoading,
  setIsSuccess,
  setIsError,
  setShowModal,
  rememberMe,
  navigate,
}) => {
  setIsLoading(true);
  try {
    const loggedIn = await hitApi({
      method: "POST",
      url: "/users/login",
      data: values,
    });

    const loggedInResponse = loggedIn.data;
    const loggedInUser = loggedInResponse.user;
    const loggedInToken = loggedInResponse.token;

    onSubmitProps.resetForm();

    if (loggedInResponse) {
      dispatch(
        setLogin({
          user: loggedInUser,
          token: loggedInToken,
        })
      );

      // Set headers for future API calls
      hitApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${loggedInToken}`;
      hitApi.defaults.headers.common["Content-Type"] = "application/json";

      if (setShowModal) {
        setShowModal(true);
      }

      // Store the token and user based on rememberMe preference
      if (rememberMe) {
        localStorage.setItem("token", loggedInToken);
        localStorage.setItem(
          "user",
          JSON.stringify({
            _id: loggedInUser._id,
            username: loggedInUser.username,
          })
        );
      } else {
        sessionStorage.setItem("token", loggedInToken);
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            _id: loggedInUser._id,
            username: loggedInUser.username,
          })
        );
      }

      // Fetch liked news and store it in Redux
      const userId = loggedInUser._id;
      const likedNewsResponse = await hitApi.get(`/users/like/${userId}`);

      dispatch(setLikedNews(likedNewsResponse.data.data.likedNews));
      dispatch(setTotalLikes(likedNewsResponse.data.data.totalLikes));
      dispatch(setTotalDislikes(likedNewsResponse.data.data.totalDislikes));

      if (setIsSuccess) {
        setIsSuccess(true);
      }

      // Refresh the page after successful login
      setTimeout(() => {
        if (navigate) {
          navigate("/home");
        }
        setIsLoading(false);
        if (setIsSuccess) {
          setIsSuccess(false);
        }
        window.location.reload();
      }, 2000);

      return true;
    }
  } catch (error) {
    console.error("Error during login:", error.message);
    if (setIsError) {
      setIsError(true);
    }
    return false;
  } finally {
    setIsLoading(false);
    onSubmitProps.resetForm();
  }
};
