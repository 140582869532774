import React, { useState } from "react";
import {
  useGetNewsWithSimilarSourcesQuery,
  useGetNewsByIdQuery,
} from "../services/api/newsServices";
import Loading from "../Loading.jsx";
import sourceImages from "../../utils/sourceImages.js";
import { useEffect } from "react";

const bucketUrl = process.env.REACT_APP_BUCKET_URL;
const parewaImg = `${bucketUrl}/backend/assets/Parewa_BLACK_WHITE.png`;
const bg = `${bucketUrl}/backend/assets/wallpapers/trying_similar_sources.jpg`;

const NewsArticle = ({ article }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const uniqueSimilarSources = [
    ...new Set(article.similar_sources.map((source) => source.source)),
  ];
  const totalUniqueSimilarSources = uniqueSimilarSources.length;

  return (
    <li className="max-w-5xl mb-4 p-4 rounded dark:bg-zinc-800 bg-zinc-100 dark:bg-opacity-40 dark:text-white text-black">
      <h2 className="text-xl font-semibold font-sans my-2 xxs:flex-col sm:flex-row flex gap-2 items-center">
        {article.title
          ? new DOMParser().parseFromString(article.title, "text/html").body
              .textContent
          : ""}
        <p className="xxs:mt-2 sm:mt-0 dark:bg-zinc-700 bg-zinc-200 rounded-lg p-1 px-2 h-fit font-semibold text-sm flex items-center w-fit">
          {totalUniqueSimilarSources} Sources
        </p>
      </h2>
      <p className="text-sm font-sans font-medium">
        {article.description
          ? new DOMParser().parseFromString(article.description, "text/html")
              .body.textContent
          : ""}
      </p>
      <div className="flex xxs:flex-col sm:flex-row w-full py-2 justify-between my-3">
        <div className="flex items-center gap-2 p-1 ml-1">
          <img
            alt="blog"
            src={sourceImages[article.source_name] || parewaImg}
            className="w-10 h-10 rounded-full object-cover object-center"
          ></img>
          <span className="news-card-source text-xs sm:text-xs md:text-sm lg:text-md xl:text-md">
            {article.source_name}
          </span>
          <div className="text-zinc-400 tracking-widest flex">
            <span className="mr-2 text-xs">
              {new Date(article.pub_date).toLocaleDateString()}
            </span>
            {article.source_name !== "Annapurna Post" &&
              article.source_name !== "Kathmandu Post" &&
              article.source_name !== "Ajako Artha" && (
                <span className="text-[10px]">
                  {new Date(article.pub_date).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              )}
          </div>
        </div>
      </div>
      <button
        onClick={toggleDropdown}
        className="text-blue-500 text-sm hover:underline my-2 shadow-lg p-1 px-2 rounded-lg dark:bg-zinc-800 bg-zinc-200"
      >
        {isOpen ? "Hide" : "View Similar Articles"}
      </button>
      {isOpen && (
        <div className="">
          {article.similar_sources.map((source, index) => (
            <SimilarSource key={index} source={source} />
          ))}
        </div>
      )}
    </li>
  );
};

const SimilarSource = ({ source }) => {
  const { data, error, isLoading } = useGetNewsByIdQuery(source.id);

  let similarNews = data?.data;

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="flex mb-2 bg-zinc-200 dark:bg-zinc-800 rounded-lg p-3">
      <li className="text-sm font-sans font-medium">
        <p className="flex gap-2 items-center">
          <img
            alt="blog"
            src={sourceImages[similarNews.source_name] || parewaImg}
            className="w-10 h-10 rounded-full object-contain object-center"
          ></img>
          {source.source}
        </p>
        <p className="my-2 text-xl">
          <strong>Title:</strong> {similarNews.title}
        </p>
        <p className="my-2">
          <strong>Description:</strong>{" "}
          {similarNews.description
            ? new DOMParser()
                .parseFromString(similarNews.description, "text/html")
                .body.textContent.slice(0, 200) + "..."
            : ""}
        </p>
      </li>
    </div>
  );
};

const NewsWithSimilarSources = () => {
  const { data: news, error, isLoading } = useGetNewsWithSimilarSourcesQuery();
  const [selectedSource, setSelectedSource] = useState("All");
  const [sortOrder, setSortOrder] = useState("desc");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [sortSourcesOrder, setSortSourcesOrder] = useState("default");

  useEffect(() => {
    if (news) {
      const oldestNewsDate = new Date(
        Math.min(...news.map((article) => new Date(article.pub_date)))
      );
      const today = new Date();
      setStartDate(oldestNewsDate.toISOString().split("T")[0]);
      setEndDate(today.toISOString().split("T")[0]);
    }
  }, [news]);

  const handleClearFilters = () => {
    setSelectedSource("All");
    setSortOrder("desc");
    setStartDate("");
    setEndDate("");
    setKeyword("");
    setSortSourcesOrder("default");
  };

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  const uniqueSources = [
    "All",
    ...new Set(news.map((article) => article.source_name)),
  ];

  const filteredNews = news
    .filter((article) =>
      selectedSource === "All" ? true : article.source_name === selectedSource
    )
    .filter((article) =>
      startDate && endDate
        ? new Date(article.pub_date) >= new Date(startDate) &&
          new Date(article.pub_date) <= new Date(endDate)
        : true
    )
    .filter((article) =>
      keyword
        ? article.title.toLowerCase().includes(keyword.toLowerCase()) ||
          article.description.toLowerCase().includes(keyword.toLowerCase())
        : true
    );

  const sortedNews = filteredNews.sort((a, b) => {
    if (sortSourcesOrder === "asc") {
      return a.similar_sources.length - b.similar_sources.length;
    } else if (sortSourcesOrder === "desc") {
      return b.similar_sources.length - a.similar_sources.length;
    } else {
      return sortOrder === "desc"
        ? new Date(b.pub_date) - new Date(a.pub_date)
        : new Date(a.pub_date) - new Date(b.pub_date);
    }
  });

  let totalNewsNumber = sortedNews.length;

  return (
    <div className="p-4 xxs:mt-7 sm:mt-16 font-Comfortaa flex justify-center">
      <div classname="w-full flex-col flex justify-center">
        <div className="max-w-screen -mx-4 -mt-4">
          {/* Background Photo */}
          <section className="flex h-96 relative">
            <img
              src={bg}
              className="absolute inset-0 object-cover object-bottom w-full h-full dark:blur-sm"
              alt="bg"
            />
            <h1 className="text-2xl xxs:p-2 sm:p-0 font-bold mb-4 text-white absolute top-10 left-5 inset-0 w-fit h-fit">
              News with Similar Sources
            </h1>
          </section>
          {/* Background Photo */}
        </div>
        <div className="flex flex-col justify-center">
          <div className="relative xxs:justify-start sm:justify-center flex flex-wrap gap-2 p-2 dark:text-white text-black items-center">
            <div className="my-2">
              <label className="mr-2">Keyword:</label>
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
                placeholder="Search by keyword"
              />
            </div>

            <div className="my-2">
              <label className="mr-2">Filter by Source:</label>
              <select
                value={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value)}
                className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
              >
                {uniqueSources.map((source) => (
                  <option key={source} value={source}>
                    {source}
                  </option>
                ))}
              </select>
            </div>

            <div className="my-2">
              <label className="mr-2">Sort by Date:</label>
              <select
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
                className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
              >
                <option value="desc">Newest First</option>
                <option value="asc">Oldest First</option>
              </select>
            </div>

            <div className="my-2 flex flex-wrap gap-2 w-full items-center xxs:justify-start sm:justify-center">
              <div>
                <label className="mr-2">Start Date:</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
                  min={
                    news
                      ? new Date(
                          Math.min(
                            ...news.map((article) => new Date(article.pub_date))
                          )
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>

              <div>
                <label className="mr-2">End Date:</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
                  min={
                    news
                      ? new Date(
                          Math.min(
                            ...news.map((article) => new Date(article.pub_date))
                          )
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>

            <div className="my-2 w-full xxs:justify-start sm:justify-center flex items-center">
              <label className="mr-2">Sort by Similar Sources:</label>
              <select
                value={sortSourcesOrder}
                onChange={(e) => setSortSourcesOrder(e.target.value)}
                className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
              >
                <option value="default">Default</option>
                <option value="asc">Min Sources First</option>
                <option value="desc">Max Sources First</option>
              </select>
            </div>

            <div className="my-2 w-full xxs:justify-start sm:justify-center flex items-center">
              <button
                onClick={handleClearFilters}
                className="p-2 border rounded text-black dark:text-parewacreamcanprimary-200 dark:bg-zinc-700 dark:border-black"
              >
                Clear Filters
              </button>
            </div>
          </div>

          <div className="mb-4 w-full flex items-center justify-center my-2 dark:text-white text-black">
            <strong>
              Total{" "}
              <span className="dark:text-parewacreamcanprimary-200">
                {totalNewsNumber}
              </span>{" "}
              Articles
            </strong>
          </div>
          <div className="w-full flex justify-center items-center">
            {sortedNews.length === 0 ? (
              <p>No news with similar sources found.</p>
            ) : (
              <ul>
                {sortedNews.map((article) => (
                  <NewsArticle key={article._id} article={article} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsWithSimilarSources;
